import React, { Component } from 'react';
import { styled } from 'styled-components';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import SignInNavbar from '../components/SignInNavbar';
import ImageTestimonial from '../components/ImageTestimonial';
import Testimonials from '../resources/Testimonials';
import media1 from '../resources/images/testimonial_image_1.jpg';
import media2 from '../resources/images/testimonial_image_2.jpg';
import { Button, Form, Input } from 'antd';
import TranslateAuth from './TranslateAuth';
import * as AsyncService from '../api/AsyncService';
import service from '../api/Service';
import ReactGA from 'react-ga';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';

import { CredentialResponse } from 'google-one-tap';
import { auth } from '..';
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import StyledFirebaseAuth from '../components/StyledFirebaseAuth';
import { TermsAndConditions } from '../components/TermsAndConditions';

type State = {
  provider?: string;
  loading: boolean;
  gsiInit: boolean;
};

type Props = {
  onLogInClick: () => void;
  callbackSignInSucceeded: (accountId: string) => void;
  t?: any;
};

class ForgotHowLoggedIn extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      gsiInit: false
    };
  }
  useDeviceLanguage() {
    var userLang = navigator.language;
    i18n.changeLanguage(userLang);
  }

  submit = async (data: { email: string }) => {
    this.setState({
      loading: true,
      provider: undefined,
    });
    var provider = await AsyncService.getAccountProvider(data.email);
    this.setState({
      provider: provider,
      loading: false
    });
    if (provider === 'google.com' && isDesktopApp()) {
      this.renderGoogleButton();
    }
  }
  signInCallback = (authResult: any, redirectUrl: string) => {
    const { callbackSignInSucceeded } = this.props;
    this.setState({ loading: true });
    const currentUser = auth.currentUser;
    if (currentUser) {
      currentUser
        .getIdToken(true)
        .then(function (idToken) {
          service.Account(idToken, currentUser.uid, response => {
            if (response) {
              service.signIn(idToken, response => {
                callbackSignInSucceeded(response.accountId);
                ReactGA.event({
                  category: 'signIn',
                  action: 'signInSucceeded',
                });
              });
            }
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }

    return false;
  };

  handleCredentialResponse(response: CredentialResponse, login: ForgotHowLoggedIn) {
    // Build Firebase credential with the Google ID token.
    const idToken = response.credential;
    const credential = GoogleAuthProvider.credential(idToken);

    // Sign in with credential from the Google user.
    const result = signInWithCredential(auth, credential)
      .then(() => {
        login.signInCallback(null, "");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The credential that was used.
        // const credential = firebase.auth.GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log("ERROR with Google login");
      });
  };

  componentDidMount() {
    if (isDesktopApp()) {
      i18n.on('languageChanged', () => { this.renderGoogleButton() });

      let scriptId = "gsiScript";
      if (document.getElementById(scriptId)) {
        this.initializeGsi();
      }
      else {
        const script = document.createElement("script");
        script.setAttribute("id", scriptId)
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);

        document.getElementById(scriptId)!.addEventListener("load", () => {
          this.initializeGsi();
        });
      }
    }
  };

  initializeGsi() {
    (window as any).google.accounts.id.initialize({
      client_id: "952297686491-i8ej3ffcpk52gpi62s5un911jhkmnkc7.apps.googleusercontent.com",
      callback: (data: any) => this.handleCredentialResponse(data, this)
    });
    this.setState({ gsiInit: true });
    this.renderGoogleButton();
  };
  renderGoogleButton() {
    if (this.state.gsiInit) {
      (window as any).google.accounts.id.renderButton(
        document.getElementById("googleLogin")!,
        { theme: "outline", size: "large", locale: localStorage.getItem('lang') || navigator.language }  // customization attributes
      );
      (window as any).google.accounts.id.prompt(); // also display the One Tap dialog
    }
  };

  render() {
    const { t } = this.props;
    const { provider, loading } = this.state;

    const validateMessages = {
      required: t('ForgotHowToLoggedIn.RequiredEmailField'),
      types: {
        email: t('ForgotHowToLoggedIn.InvalidEmail'),
      },
    };

    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: this.signInCallback,
      },
      signInFlow: isDesktopApp() ? 'redirect' : 'popup',
    };

    let googleTdButton;
    let isDesktop = isDesktopApp();
    if (isDesktop) {
      googleTdButton = <div style={{ marginBottom: '14px' }}><div id="googleLogin"></div></div>;
    }
    else {
      googleTdButton = <StyledFirebaseAuth
        key={Math.random()}
        uiConfig={{
          ...uiConfig,
          signInOptions: [GoogleAuthProvider.PROVIDER_ID]
        }}
        firebaseAuth={auth}
        className="firebase-assuth"
      />;
    }
    const language = localStorage.getItem('lang');
    return (
      <SignUpPage>
        <SignInNavbar
          isWeb={!isDesktop}
          handleClickLogIn={this.props.onLogInClick}
          handleClickSignUp={() => { }}
        />
        <div
          className="d-flex"
          style={{ width: '100%', height: 'calc(100% - 50px)' }}>
          <SignUpForm>
            <div
              style={{ width: '100%', maxWidth: '380px', textAlign: 'center' }}>
              <TitleHeader>{t('ForgotHowToLoggedIn.HavingTrouble')}</TitleHeader>
              <SubtitleHeader>{t('ForgotHowToLoggedIn.TypeEmail')}</SubtitleHeader>
            </div>
            <Form style={{ width: '100%', maxWidth: '300px' }} onFinish={this.submit} layout='vertical' validateMessages={validateMessages}>
              <Form.Item
                label={t('Account.Email')}
                name="email"
                rules={[{ required: true, type: 'email', }]}
              >
                <Input />
              </Form.Item>
              {provider === 'notFound' && <>
              </>}
              <Button type='primary' style={{ width: '100%' }} loading={loading} htmlType='submit'>
                {t('ForgotHowToLoggedIn.Search')}
              </Button>
            </Form>
            {provider === 'notFound' && <Message>{t('ForgotHowToLoggedIn.NotFound')}</Message>}
            {provider != null && provider !== '' && provider !== 'notFound' && <Message>{t('ForgotHowToLoggedIn.AccessSystem')}</Message>}
            <TranslateAuth type="SignIn" uiCallBack={(showGoogleButton) => {
                var googleButton = document.getElementById("googleLogin");
                if (googleButton) {
                  googleButton.style.display = showGoogleButton ? "block" : "none";
                }
              } 
            }>
              {provider === 'google.com' && googleTdButton}
              {provider === 'facebook.com' && <StyledFirebaseAuth
                key={Math.random()}
                uiConfig={{
                  ...uiConfig,
                  signInOptions: [FacebookAuthProvider.PROVIDER_ID]
                }}
                firebaseAuth={auth}
                className="firebase-assuth"
              />}
              {provider === 'apple.com' && <StyledFirebaseAuth
                key={Math.random()}
                uiConfig={{
                  ...uiConfig,
                  signInOptions: ['apple.com']
                }}
                firebaseAuth={auth}
                className="firebase-assuth"
              />}
              {provider === 'password' && <StyledFirebaseAuth
                key={Math.random()}
                uiConfig={{
                  ...uiConfig,
                  signInOptions: [
                    {
                      provider: EmailAuthProvider.PROVIDER_ID,
                      requireDisplayName: false,
                    },
                    EmailAuthProvider.PROVIDER_ID]
                }}
                firebaseAuth={auth}
                className="firebase-assuth"
              />}
            </TranslateAuth>
          </SignUpForm>
          <ImageTestimonial
            testimonials={Testimonials}
            images={[media1, media2]}
          />
        </div>
        <TermsAndConditions language={language ?? 'en'} t={t} />
      </SignUpPage>
    );
  }
}
export default withTranslation()(ForgotHowLoggedIn);

const SignUpPage = styled.div`
  width: 100%;
  height: calc(100vh - 10px);
  .firebaseui-idp-list > .firebaseui-list-item:nth-child(2) {
    display: none;
  }
`;

const SignUpForm = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RedirectToSignIn = styled.div`
  color: var(--woodsmoke-900, #3d3d3d);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;

const LinkText = styled.span`
  color: var(--picton-blue-500, #01b6e9);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
  cursor: pointer;
`;

const FormLabel = styled.div`
  color: var(--woodsmoke-900, #3d3d3d);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
  margin-top: 20px;
`;

const TitleHeader = styled.h2`
  color: var(--basic-black, #0f0f0f);
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 120% */
  letter-spacing: -0.6px;
`;

const SubtitleHeader = styled.label`
  color: var(--basic-black, #0f0f0f);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 16px;
`;

const Message = styled.label`
  color: var(--basic-black, #0f0f0f);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 16px;
  width: 300px;
  text-align: center;
  padding-top: 20px;
`;