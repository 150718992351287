import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { BoardMenuItem } from '../menu/MenuViewModel';
import { colors } from '../theme';
import { CopyBoardResponse } from '../typings/board';
import { Dictionary } from '../typings/utils';
import CopyBoardButton from './CopyBoardButton';
import Spinner from '../resources/Spinner';
// import { CopyBoardButton } from
import ShareBoardButton from './ShareBoardButton';

type Props = {
  editing: boolean;
  index: number;
  item: BoardMenuItem;

  onClick: (item: BoardMenuItem) => void;
  onBoardCopied: (response: CopyBoardResponse) => void;
  onDeleteClick: (item: BoardMenuItem) => void;
  onTitleSubmit: (itemId: BoardMenuItem, title: string) => void;
  setEditing: Dispatch<SetStateAction<Dictionary<boolean>>>;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

export class BoardListItem extends React.PureComponent<Props> {
  render() {
    return <BoardListItemFC {...this.props} />;
  }
}

const BoardListItemFC: React.FC<Props> = ({
  editing,
  index,
  item,
  onClick,
  onBoardCopied,
  onDeleteClick,
  onTitleSubmit,
  setEditing,
  setModalVisible,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setTitle(item.title);
  }, [item.title]);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const updateEditing = useCallback(
    (v: boolean) => {
      setEditing(s => ({
        ...s,
        [item.boardId]: v,
      }));
    },
    [item.boardId, setEditing],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current) {
        return;
      }
      const node = containerRef.current;

      if (editing && node && !node.contains(event.target as Node)) {
        updateEditing(false);
        onTitleSubmit(item, title);
      }
    },
    [editing, item, title, onTitleSubmit, updateEditing],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClick = useCallback(() => {
    return onClick(item);
  }, [item, onClick]);

  const handleDeleteClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      return onDeleteClick(item);
    },
    [item, onDeleteClick],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (editing) {
          onTitleSubmit(item, title);
          updateEditing(false);
        } else {
          updateEditing(true);
        }
      } else if (!editing && e.key === 'Delete') {
        //Delete (fn+backspace)
        onDeleteClick(item);
      } else if (editing && e.key === 'Escape') {
        // Escape should cancel editing
        setTitle(item.title);
        updateEditing(false);
        // TODO: cancel editing of new board by deleting it\
      }
    },
    [editing, item, title, onDeleteClick, onTitleSubmit, updateEditing],
  );

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    [],
  );

  const handleTitleEdit = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      updateEditing(true);
    },
    [updateEditing],
  );

  const handleTitleSubmit = useCallback(() => {
    onTitleSubmit(item, title);
    updateEditing(false);
  }, [item, title, onTitleSubmit, updateEditing]);

  const renderItem = useCallback(
    (provided: DraggableProvided) => {
      let data: any = localStorage.getItem('AccountInfo');
      const accountInfo = JSON.parse(data);
      return (
        <MenuItem
          className="board-list-item"
          selected={item.isSelected}
          ref={provided.innerRef}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <MenuItemContent ref={containerRef}>
            <MenuItemTitleInput
              editing={editing}
              selected={item.isSelected}
              ref={inputRef as any}
              value={title}
              onBlur={handleTitleSubmit}
              onChange={handleTitleChange}
            />
            {!editing && (
              <MenuItemTitle selected={item.isSelected}>{title}</MenuItemTitle>
            )}
            <MenuItemActions className="board-list-item-icon-container">
            {/* Hide temporarily while it's not defined what to do. */}
              {/* {accountInfo?.IsKanbanaPremiumEnabled === true && (
                <ShareBoardButton
                  boardId={item.boardId}
                  setModalVisible={setModalVisible}
                />
              )}
              {accountInfo?.IsKanbanaPremiumEnabled === true && (
                <CopyBoardButton
                  boardId={item.boardId}
                  iconSize="1x"
                  onBoardCopied={onBoardCopied}
                  setModalVisible={setModalVisible}
                />
              )} */}
              {!editing && (
                <>
                  <EditDeleteButton
                    className="board-list-item-icon"
                    editing={editing}
                    title="Delete this card"
                    onClick={handleDeleteClick}>
                    <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                  </EditDeleteButton>
                  <EditDeleteButton
                    className="board-list-item-icon"
                    editing={editing}
                    title="Edit this board"
                    onClick={handleTitleEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                  </EditDeleteButton>
                </>
              )}
            </MenuItemActions>
          </MenuItemContent>
        </MenuItem>
      );
    },
    [
      editing,
      item,
      title,
      handleClick,
      handleDeleteClick,
      handleKeyDown,
      handleTitleChange,
      handleTitleEdit,
      handleTitleSubmit,
      onBoardCopied,
      setModalVisible,
    ],
  );

  return (
    <Draggable draggableId={item.boardId} index={index}>
      {provided => renderItem(provided)}
    </Draggable>
  );
};

type MenuItemProps = {
  editing?: boolean;
  selected?: boolean;
};

type EditDeleteButtonProps = {
  editing?: boolean;
};

const EditDeleteButton = styled.div<EditDeleteButtonProps>`
  cursor: pointer;
  margin-left: 8px;
  width: 24px;

  &:hover svg {
    color: ${colors.task.blue} !important;
  }
`;

const MenuItem = styled.a<MenuItemProps>`
  align-items: center;
  background-color: ${({ editing }) =>
    !editing ? colors.white : colors.task.blue};
  box-sizing: border-box;
  color: ${colors.menu.link};
  display: block;
  padding-left: 16px;
  text-decoration: none;
  width: 100%;

  & svg {
    color: ${colors.task.grey} !important;
  }

  &:hover,
  &:hover p {
    color: ${({ editing }) =>
      !editing ? colors.task.blue : colors.task.grey} !important;
  }
`;

const MenuItemContent = styled.div`
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding-right: 16px;
`;

const MenuItemActions = styled.div`
  display: flex;
  flex-direction: row;

  & svg {
    color: ${colors.black} !important;
  }
`;

const MenuItemTitle = styled.p<{ selected: boolean }>`
  background-color: transparent;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  margin-left: -8px;
  margin-right: -8px;
  padding: 0 8px;
`;

type MenuItemTitleInputProps = {
  editing: boolean;
  selected: boolean;
};

const MenuItemTitleInput = styled.input<MenuItemTitleInputProps>`
  background: transparent;
  border: 0px;
  display: ${({ editing }) => (editing ? 'inline-block' : 'none')};
  font-family: sans-serif;
  font-size: 1em;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  margin: 0;
  outline: none;
  padding-bottom: 0;
  padding-top: 0;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;
