import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { Action, State } from '../typings/state';
import { Story } from '../typings/story';
import { Trigger } from '../typings/synchronize';
import { getBoards, getBoardStages, getStageStories } from './computed';
import { populateStoreFromDB, synchronize } from './operations';
import { initialState, reducer } from './reducer';
import { useDatabase } from '../database/hooks';
import { SET_LAST_SYNCHRONIZED_AT } from './actions';
import { uniq } from 'lodash';

const contextInitialValues = {
  state: initialState,
  dispatch: () => { },
};

export type ContextValues = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const StoreContext = createContext<ContextValues>(contextInitialValues);

const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

export const StoreProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const database = useDatabase();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  const executeSync = async (forceSyncToServer?: boolean) => {
    if (
      loading ||
      !state.accountId ||
      !state.activeBoardId ||
      !state.boards ||
      !state.lastSynchronizedAt ||
      !state.stages ||
      !state.stories
    ) {
      return;
    }

    setLoading(true);
    // Check if there are any changes to the stages or stories and push the updated objects into arrays:
    const updatedBoards: Board[] = [];
    const currentBoards = getBoards(state.boards, false);
    for (let i = 0; i < currentBoards.length; i++) {
      const board = currentBoards[i];
      if (
        board.createdAt &&
        board.updatedAt &&
        state.lastSynchronizedAt &&
        new Date(board.updatedAt) > new Date(state.lastSynchronizedAt)
      ) {
        updatedBoards.push(board);
      }
    }
    const currentBoardStages: Stage[] = getBoardStages(
      state.stages,
      state.stagesIndex,
      state.activeBoardId,
      false,
    );
    const updatedStages: Stage[] = [];
    let updatedStories: Story[] = [];
    for (let i = 0; i < currentBoardStages.length; i++) {
      const stage = currentBoardStages[i];
      if (
        stage.createdAt &&
        stage.updatedAt &&
        state.lastSynchronizedAt &&
        stage.updatedAt > state.lastSynchronizedAt
      ) {
        updatedStages.push(stage);
      }

      // const currentStageStories: Story[] = getStageStories(
      //   state.stories,
      //   state.storiesIndex,
      //   stage,
      //   false,
      // );

      const allStageStories: Story[] = Object.values(state.stories);
      for (let j = 0; j < allStageStories.length; j++) {
        const story = allStageStories[j];
        if (
          story.createdAt &&
          story.updatedAt &&
          state.lastSynchronizedAt &&
          new Date(story.updatedAt) > new Date(state.lastSynchronizedAt)
        ) {
          updatedStories.push(story);
        }
      }
      updatedStories = (uniq(updatedStories));
    }
    if (
      !updatedBoards.length &&
      !updatedStages.length &&
      !updatedStories.length
    ) {
      setLoading(false);
      return;
    }

    if (forceSyncToServer === true) {
      try {
        await synchronize(
          database,
          dispatch,
          {
            accountId: state.accountId,
            trigger: Trigger.User,
            pusher: 9
          },
          updatedBoards,
          updatedStages,
          updatedStories,
          state.lastSynchronizedAt,
        );
      } catch (e) {
        await delay(3000);
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const lastSynchronizedAt = localStorage.getItem('lastSynchronizedAt');
    if (lastSynchronizedAt) {
      dispatch({
        type: SET_LAST_SYNCHRONIZED_AT,
        payload: new Date(lastSynchronizedAt),
      });
    }
    setInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    if (state.internalUpdateDate) {
      executeSync(true);
    }
  }, [state.internalUpdateDate]);

  useEffect(() => {
    if (initialized) {
      executeSync();
    }
  }, [initialized]);

  useEffect(() => {
    if (state.lastSynchronizedAt) {
      localStorage.setItem(
        'lastSynchronizedAt',
        state?.lastSynchronizedAt?.toString(),
      );
    }
  }, [state.lastSynchronizedAt]);

  useEffect(() => {
    if (database) {
      populateStoreFromDB(database, dispatch);
    }
  }, [database]);

  useEffect(() => {
    if (state.boards || state.stages || state.stories) {
      executeSync();
    }
  }, [state.boards, state.stages, state.stories])

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
};
