import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IDBPDatabase } from 'idb';

import { Database } from '../typings/database';
import { initialize } from './initialize';

type Props = PropsWithChildren<{}>;

export const DatabaseProvider: React.FC<Props> = ({ children }) => {
  const [database, setDatabase] = useState<IDBPDatabase<Database>>();

  const initializeIDB = useCallback(async () => {
    const db = await initialize();
    if (db) {
      setDatabase(db);
    }
  }, []);

  useEffect(() => {
    initializeIDB();
  }, [initializeIDB]);

  return (
    <DatabaseContext.Provider value={database}>
      {children}
    </DatabaseContext.Provider>
  );
};

export const DatabaseContext = createContext<
  IDBPDatabase<Database> | undefined
>(undefined);
