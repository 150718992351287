// They are action types to be exact. Action types are used to dispatch actions to store's reducer

/**
 * Service actions
 */

export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';

export const LOAD_BOARDS_SUCCESS = 'LOAD_BOARDS_SUCCESS';
export const LOAD_STAGES_SUCCESS = 'LOAD_STAGES_SUCCESS';
export const LOAD_STORIES_SUCCESS = 'LOAD_STORIES_SUCCESS';

export const SYNCHRONIZE_SUCCESS = 'SYNCHRONIZE_SUCCESS';

/**
 * Local actions
 */

export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

export const ADD_BOARD_LOCALLY = 'ADD_BOARD_LOCALLY';
export const DELETE_BOARD_LOCALLY = 'DELETE_BOARD_LOCALLY';
export const SET_ACTIVE_BOARD = 'SET_ACTIVE_BOARD';
export const UPDATE_BOARD_LOCALLY = 'UPDATE_BOARD_LOCALLY';
export const UPDATE_BOARDS_LOCALLY = 'UPDATE_BOARDS_LOCALLY';

export const ADD_STAGE_LOCALLY = 'ADD_STAGE_LOCALLY';
export const BLUR_STAGE_LOCALLY = 'BLUR_STAGE_LOCALLY';
export const DELETE_STAGE_LOCALLY = 'DELETE_STAGE_LOCALLY';
export const FOCUS_STAGE_LOCALLY = 'FOCUS_STAGE_LOCALLY';
export const UPDATE_STAGE_LOCALLY = 'UPDATE_STAGE_LOCALLY';
export const UPDATE_STAGES_LOCALLY = 'UPDATE_STAGES_LOCALLY';

export const ADD_STORY_LOCALLY = 'ADD_STORY_LOCALLY';
export const BLUR_STORY_LOCALLY = 'BLUR_STORY_LOCALLY';
export const DELETE_STORY_LOCALLY = 'DELETE_STORY_LOCALLY';
export const COMPLETE_STORY_LOCALLY = 'COMPLETE_STORY_LOCALLY';
export const FOCUS_STORY_LOCALLY = 'FOCUS_STORY_LOCALLY';
export const UPDATE_STORY_LOCALLY = 'UPDATE_STORY_LOCALLY';
export const OPEN_PREMIUM_MODAL = 'OPEN_PREMIUM_MODAL';
export const MOVE_CARD_SAME_COLUMN = 'MOVE_CARD_SAME_COLUMN';
export const MOVE_CARD_DIFFERENT_COLUMN = 'MOVE_CARD_DIFFERENT_COLUMN';

export const SET_LAST_SYNCHRONIZED_AT = 'SET_LAST_SYNCHRONIZED_AT';

export const RESET_STATE = 'RESET_STATE';
export const MOVE_STORY = 'MOVE_STORY';
