import axios from 'axios';
import * as uuid from 'uuid';

export const generateInstance = () => {
  let syncId = sessionStorage.getItem('com.kanbana.syncId');
  if (!syncId) {
    syncId = uuid.v1();
    sessionStorage.setItem('com.kanbana.syncId', syncId);
  }
  let languageCode = localStorage.getItem('lang');

  return axios.create({
    baseURL: process.env.REACT_APP_KANBANA_SERVICE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': '28DB0B2C-C3A9-4641-AC1E-AC08DE632845',
      'X-Client-Device': 'Browser',
      'X-Client-Os': navigator.platform,
      'X-Client-SyncId': syncId,
      'X-Client-LanguageCode': languageCode,
    },
  });
};

// const instance = generateInstance();

// export default instance;
