import { ReactComponent as trash } from '../../assets/svg/trash.svg';
import { ReactComponent as cardTrash } from '../../assets/svg/evil-trash.svg';
import { ReactComponent as pencil } from '../../assets/svg/pencil.svg';
import { ReactComponent as dots } from '../../assets/svg/dots.svg';
import { ReactComponent as plus } from '../../assets/svg/plus.svg';

const all = {
  trash,
  pencil,
  dots,
  plus,
  cardTrash,
};

export type TypeIcons = keyof typeof all;
export default all;
