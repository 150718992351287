import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import styled from 'styled-components';

import { colors, sizes, borderRadius } from '../theme';
import { Board } from '../typings/board';
import { DRAGGABLE_TYPE } from '../typings/draggable';
import { Stage } from '../typings/stage';
import { StageDetailsContainer } from './StageDetails.container';
import { useTranslation, withTranslation } from 'react-i18next';
import { PeriodCompletedCards } from './ViewModels';

type Props = {
  allActivesStages: Stage[];
  allActivesBoards: Board[];
  board: Board;
  lastSyncAt?: number;
  readOnly?: boolean;
  stages: Stage[];
  token?: string;
  t?: any;
  isBoldColors: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  onAddStageClick?: () => void;
  onStageDragEnd?: (
    destination: DraggableLocation,
    source: DraggableLocation,
  ) => void;
  onStoryDragEnd?: (
    destination: DraggableLocation,
    source: DraggableLocation,
  ) => void;
};

const BoardDetails: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const handleDragStart = () => {
    // $ExpectError - body wont be null
    document.body.classList.add(isDraggingClassName);
  };

  const handleDragEnd = (result: DropResult) => {
    const { onStageDragEnd, onStoryDragEnd } = props;
    // $ExpectError - body wont be null
    document.body.classList.remove(isDraggingClassName);

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { destination, source } = result;

    if (result.type === DRAGGABLE_TYPE.STORY) {
      onStoryDragEnd && onStoryDragEnd(destination, source);
    } else if (result.type === DRAGGABLE_TYPE.STAGE) {
      onStageDragEnd && onStageDragEnd(destination, source);
    }
  };

  const renderAddStage = () => {
    const { readOnly, onAddStageClick } = props;
    const { t } = props
    if (readOnly) {
      return null;
    }
    return <AddStageLink onClick={onAddStageClick}> {t('Board.addColumn')}</AddStageLink>;
  };

  const renderBoard = () => {
    localStorage.setItem('stories', '0');

    const { board, readOnly, stages, isBoldColors, isCompletedCards, selectedPeriodCompletedCards } = props;
    return (
      <Droppable direction="horizontal" droppableId={board.id} type="STAGE">
        {(provided: DroppableProvided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {stages.map((stage, index) => (
              <StageDetailsContainer
                t={t}
                allActivesStages={props.allActivesStages}
                allActivesBoards={props.allActivesBoards}
                accountId={board.accountId}
                isBoldColors={isBoldColors}
                index={index}
                key={stage.id}
                readOnly={readOnly}
                stage={stage}
                isCompletedCards={isCompletedCards}
                selectedPeriodCompletedCards={selectedPeriodCompletedCards}
              />
            ))}
            {renderAddStage()}
          </Container>
        )}
      </Droppable>
    );
  };

  return (
    <>
      {/* {localStorage.getItem('shareToken') === 'true' && (
        <Spinner/>
      )} */}
      <DragDropContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        {renderBoard()}
      </DragDropContext>
    </>
  );
}
export default withTranslation()(BoardDetails)
const isDraggingClassName = 'is-dragging';

const Container = styled.div`
  background: ${colors.lightgrey};

  /* like display:flex but will allow bleeding over the window width */
  display: inline-flex;
   width: 100%;
   height:calc(100vh - 58px);
  overflow: auto;
  margin-top:5px
`;

//todo pri1: box-sizing: border-box; should be used all over instead of subtracting padding from with
const AddStageLink = styled.a`
  box-sizing: border-box;
  width: ${sizes.stage.width}px;
  min-width: ${sizes.stage.width}px;
  height: 50px;
  margin-left: ${sizes.stage.margin}px;
  margin-right: ${sizes.stage.margin}px;
  padding: 16px 0px 0px ${sizes.stage.titlePaddingLeft}px;
  border-radius: ${borderRadius}px;
  color: ${colors.darkgrey};
  background: ${colors.stage.backgroundColor};
  font-size: 1em;
  font-family: sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background-color: ${colors.stage.hover};
  }
`;
