import React, { Component , MouseEvent, RefObject, createRef } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { colors } from '../theme';
import { MENU_ANIMATION_DURATION } from '../utils/constants';
import { BoardMenuItem } from './MenuViewModel';
import { withTranslation } from 'react-i18next';


type Props = {
  isVisible: boolean;
  isPremium?: boolean;
  menuItems: BoardMenuItem[];
  onAccountClick: () => void;

  onBoardSelect: (item: BoardMenuItem) => void;
  onBoardsClick: () => void;
  onDismiss: () => void;
  onSignOutClick: () => void;
  t? : any;
};
// type State = {
//   isPremium: boolean;
// };

 class Menu extends Component<Props> {
  ref: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
   
    this.ref = createRef<HTMLDivElement>();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let dataPremium:any = localStorage.getItem('isKanbanaPremiumEnabled')
  //  console.log('dada', dataPremium)
    if(dataPremium === 0){
      this.setState({
        isPremium : false
      });
    }
    else if(dataPremium === 1){
      this.setState({
        isPremium : true
      });
    }
  }

  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    const { isVisible, onDismiss } = this.props;
    if (
      this.ref.current &&
      !this.ref.current.contains(event.target) &&
      isVisible
    ) {
      onDismiss();
    }
  }
  handleAccountClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    this.props.onAccountClick();
  };
  handleBoardsClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    this.props.onBoardsClick();
  };

  render() {
    const {
      isVisible = false,
      menuItems,
      onBoardSelect,
      onSignOutClick,
      
    } = this.props;
    const { t } = this.props;
    return (
      <SlideMenu isVisible={isVisible} ref={this.ref}>
        <MenuHeaderWrapper
          style={{ cursor: 'pointer' }}
          onClick={this.handleBoardsClick}>
          <MenuHeader>{t('SideMenu.TaskBoards')}</MenuHeader>
          <FloatRight>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="1x"
              style={{ color: colors.white }}
            />
          </FloatRight>
        </MenuHeaderWrapper>
        
        {menuItems.map(item => (
          
          <MenuItem
            key={item.boardId}
            isSelected={item.isSelected}
            onClick={() => onBoardSelect(item)}>
            {item.title}
          </MenuItem>
        ))}
        {/* <MenuHeaderWrapper>
          <MenuHeader>Account</MenuHeader>
        </MenuHeaderWrapper> */}
        <MenuHeaderWrapper >
          <MenuHeader  style={{ cursor: 'pointer' }}    onClick={this.handleAccountClick} >{t('SideMenu.KanbanaPremium')}</MenuHeader>
          <FloatRight>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="1x"
              style={{ color: colors.white }}
            />
          </FloatRight>
        </MenuHeaderWrapper>
        
        
        <MenuHeaderWrapper >
          <MenuHeader  >{t('SideMenu.MyAccount')}</MenuHeader>
        </MenuHeaderWrapper>
      
        <MenuItem onClick={onSignOutClick}>{t('SideMenu.Signout')}</MenuItem>
      </SlideMenu>
    );
  }
}
export default withTranslation()(Menu)

const slideMenuWidth = 200;

type SlideMenuProps = {
  isVisible: boolean;
};

const SlideMenu = styled.div<SlideMenuProps>`
  position: absolute;
  z-index: 20;
  background-color: ${colors.menu.background};
  width: ${slideMenuWidth}px;
  min-height: 100vh !important;
  transition: left ${MENU_ANIMATION_DURATION}ms ease;
  left: ${({ isVisible }) => (isVisible ? '0px' : `-${slideMenuWidth}px`)};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height:calc(100vh - 65px);
  overflow:auto;
  top:52px
`;

type MenuItemProps = {
  isSelected?: boolean;
};

const MenuItem = styled.a<MenuItemProps>`
  display: flex;
  align-items: center;
  height: 30px;
  text-decoration: none;
  padding-left: 10px;
  color: ${colors.menu.link};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background-color: ${colors.task.orange};
  }
`;

const MenuHeaderWrapper = styled.div`
  align-items: center;
  background-color: #aaaaaa;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 6px;

`;

const MenuHeader = styled.h3`
  align-items: center;
  background-color: #aaaaaa;
  color: #ffffff;
  display: inline-block;
  margin: 0;
`;

const FloatRight = styled.div`
  padding-right: 6px;
`;
