import { openDB } from 'idb';

import { Database } from '../typings/database';
import { INDEXED_DB_NAME } from '../utils/constants';
import { checkDBSupport } from './utils';

export const initialize = async () => {
  try {
    checkDBSupport();
  } catch {
    return;
  }

  const database = await openDB<Database>(INDEXED_DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('boards')) {
        db.createObjectStore('boards', { keyPath: 'id' });
      }
      if (!db.objectStoreNames.contains('stages')) {
        db.createObjectStore('stages', { keyPath: 'id' });
      }
      if (!db.objectStoreNames.contains('stories')) {
        db.createObjectStore('stories', { keyPath: 'id' });
      }
    },
  });

  return database;
};
