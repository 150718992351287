import React, { ChangeEvent, Component, CSSProperties, RefObject } from 'react';
import styled from 'styled-components';

import { Input as StyledInput } from '../styling/Components';
import { EmojiPicker, EmojiTarget } from './EmojiPicker';

const pickerHeight = 168;

type Props = {
  autoFocus?: boolean;
  name: string;
  style?: CSSProperties;
  target?: EmojiTarget;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (v: string) => void;
  onKeyDown?: (e: any) => void;
};

type State = {
  emojiSearch: string;
  focusing: boolean;
};

export class Input extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.inputRef = React.createRef();
    this.ref = React.createRef();
    this.state = { emojiSearch: '', focusing: false };
  }

  inputRef: RefObject<HTMLInputElement>;
  ref: RefObject<HTMLDivElement>;

  componentDidMount() {
    setTimeout(() => this.inputRef.current?.focus(), 0);
  }

  calculatePickerX = () => {
    if (!this.ref.current) {
      return 0;
    }
    const { clientHeight, offsetTop } = this.ref.current;
    if (offsetTop >= pickerHeight) {
      return -pickerHeight;
    }
    return clientHeight;
  };

  handleEmojiSelect = (emoji: string) => {
    const { value, onChange } = this.props;
    const lastColonIndex = value.lastIndexOf(':');
    const newValue = value.slice(0, lastColonIndex) + emoji;

    onChange(newValue);
    this.setState({
      emojiSearch: '',
    });
  };

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.props.onChange(value);
    const lastColonIndex = value.lastIndexOf(':');
    if (lastColonIndex !== -1) {
      this.setState({
        emojiSearch: value.slice(lastColonIndex + 1),
      });
    } else {
      this.setState({
        emojiSearch: '',
      });
    }
  };

  render() {
    const { target, ...props } = this.props;
    const { emojiSearch } = this.state;
    let pickerStyle = {
      left: 0,
      position: 'absolute',
      top: this.calculatePickerX(),
      width: '100%',
    };

    return (
      <Container ref={this.ref}>
        <StyledInput
          {...props}
          ref={this.inputRef}
          onChange={this.handleTextChange}
        />
        {emojiSearch && target && (
          <EmojiPicker
            search={emojiSearch}
            style={pickerStyle}
            target={target}
            onSelect={this.handleEmojiSelect}
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
`;
