import React from 'react';
import { TEMPLATES } from '../utils/template';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

type Props = {
  accountId: string,
}

export const Template = ({ accountId }: Props) => {
  const { t } = useTranslation();
  const columns = orderBy(TEMPLATES.find(x => x.id === accountId)!.columns, ["priority"]);
  return (
    <Container>
      {columns.map((column, index) => <Column key={index}>{t('Template.' + column.title)}</Column>)}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  height: 50px;
  width: 240px;
  padding-left: 15px;
  color: #4d4d4d;
  font-weight: 600;
`;