import styled from 'styled-components';
import React from 'react';

const Icon = (props: any) => (
  <div style={{ ...props.style }} className={props.className}>
    {props.children}
  </div>
);

export const IconStyled = styled(Icon)`
  width: fit-content;
  height: fit-content;
  &.bk-circle {
    background-color: #f3f6fc;
    border-radius: 20px;
    padding: 6px 10px;
  }
  &.bk-circle.light {
    background-color: #f3f6fc;
    color: #2c2c31;
  }
  &.bk-circle.dark {
    background-color: #2c2c31;
    color: #ffffff;
  }
  svg {
    font-size: ${props => props.style.fontSize}px;
  }
`;
