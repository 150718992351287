// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Spinner from '../resources/Spinner';
import { useGetSharedBoard } from '../services/useGetSharedBoard';
import { useStoreContext } from '../store';
import { getBoardStages } from '../store/computed';
import { Board } from '../typings/board';
import BoardDetails from './BoardDetails';

type Props = {
  path: string;
  token: string;
};

export const SharedBoardContainer: React.FC<Props> = ({ token }) => {
  const getBoardService = useGetSharedBoard();
  const [board, setBoard] = useState<Board>();
  const [loading, setLoading] = useState(false);
  const { state } = useStoreContext();

  const getBoard = useCallback(async () => {
    if (!token) {
      return;
    }
    try {
      setLoading(true);
      const board = await getBoardService(token);
      if (board) {
        setBoard(board);
      }
    } finally {
      setLoading(false);
    }
  }, [token, getBoardService]);

  useEffect(() => {
    getBoard();
  }, [getBoard]);

  const stages = useMemo(() => {
    if (!board) {
      return [];
    }
    return getBoardStages(state.stages, state.stagesIndex, board.id);
  }, [board, state.stages, state.stagesIndex]);

  if (loading) {
    return <Spinner />;
  }

  if (!board) {
    return null;
  }

  return (
    <div style={{ paddingTop: 10 }}>
      <BoardDetails
        allActivesBoards={[]}
        allActivesStages={[]}
        board={board}
        readOnly
        stages={stages}
        isCompletedCards={false}
        selectedPeriodCompletedCards={{
          periodType: 'Days',
          period: 1,
        }}
        isBoldColors={true}
      />
    </div>
  );
};
