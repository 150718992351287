import React, { useState } from 'react';
import { Button, MenuProps } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { DropdownLaguage, Flag, FlexLaguage } from '../sidebar/Sidebar.styled';
import en from '../assets/images/flags/en.png';
import es from '../assets/images/flags/es.png';
import fr from '../assets/images/flags/fr.png';
import de from '../assets/images/flags/de.png';
import hi from '../assets/images/flags/hi.png';
import ja from '../assets/images/flags/ja.png';
import ko from '../assets/images/flags/ko.png';
import pt from '../assets/images/flags/pt.png';
import ru from '../assets/images/flags/ru.png';
import { Locale } from 'antd/lib/locale';
import { GetLanguageMetadata } from '../utils/lang';
import service from '../api/Service';
import { getAccountId } from '../utils/users';
import { useIntercom } from 'react-use-intercom';
import { isEmpty, isNil } from 'lodash';

export interface LanguageSelectorProps {
  lang: string;
  langFlg: string;
  langFullName: string;
  activelanguage: (lang: string, flag: string, fullName: string, antdLocale: Locale, langShortValue: string) => void;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const [openLanguage, setOpenLanguage] = useState(false);
  const { update, shutdown, boot } = useIntercom();
  const selectLang = (lang: string) => {
    const { currentAntdLocale, langShortValue } = GetLanguageMetadata(lang);
    const accountID = getAccountId();
    if (accountID === '') {
      try {
        shutdown();
        setTimeout(() => boot({ languageOverride: langShortValue }), 500);
      } catch (err) {
        console.log(err)
      }

    } else {
      service.changeLanguage(lang, getAccountId());
      try {
        update({ languageOverride: langShortValue });
      } catch (err) {
        console.log('Couldn`t update language', err);
      }

    }

    switch (lang) {
      case 'fr':
        props.activelanguage('fr', fr, 'Français', currentAntdLocale, langShortValue);
        break;
      case 'ja':
        props.activelanguage('ja', ja, '日本語', currentAntdLocale, langShortValue);
        break;
      case 'es':
        props.activelanguage('es', es, 'Español', currentAntdLocale, langShortValue);
        break;
      case 'de':
        props.activelanguage('de', de, 'Deutsch', currentAntdLocale, langShortValue);
        break;
      case 'ru':
        props.activelanguage('ru', ru, 'Русский', currentAntdLocale, langShortValue);
        break;
      case 'pt':
        props.activelanguage('pt', pt, 'Português', currentAntdLocale, langShortValue);
        break;
      case 'hi':
        props.activelanguage('hi', hi, 'हिंदी', currentAntdLocale, langShortValue);
        break;
      case 'ko':
        props.activelanguage('ko', ko, '한국인', currentAntdLocale, langShortValue);
        break;
      default:
        props.activelanguage('en', en, 'English', currentAntdLocale, langShortValue);
        break;
    }
    setOpenLanguage(false);
  };


  const items: MenuProps['items'] = [
    {
      key: 'en',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="English" src={en} /> English
        </FlexLaguage>
      ),
    },
    {
      key: 'fr',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="Français" src={fr} /> Français
        </FlexLaguage>
      ),
    },
    {
      key: 'ja',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="日本語" src={ja} /> 日本語
        </FlexLaguage>
      ),
    },
    {
      key: 'es',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="Español" src={es} /> Español
        </FlexLaguage>
      ),
    },
    {
      key: 'de',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="Deutsch" src={de} /> Deutsch
        </FlexLaguage>
      ),
    },
    {
      key: 'ru',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="Русский" src={ru} /> Русский
        </FlexLaguage>
      ),
    },
    {
      key: 'pt',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="Português" src={pt} /> Português
        </FlexLaguage>
      ),
    },
    {
      key: 'hi',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="हिंदी" src={hi} /> हिंदी
        </FlexLaguage>
      ),
    },
    {
      key: 'ko',
      onClick: menu => selectLang(menu.key),
      label: (
        <FlexLaguage>
          <Flag alt="한국인" src={ko} /> 한국인
        </FlexLaguage>
      ),
    },
  ];


  return (
    <div>
      <DropdownLaguage
        open={openLanguage}
        onOpenChange={open => setOpenLanguage(open)}
        menu={{ items }}
        placement="bottomLeft"
        trigger={['click']}>
        <Button size="large">
          <FlexLaguage>
            <Flag alt={props.langFullName} src={props.langFlg} />{' '}
            {props.langFullName}
            {openLanguage ? <UpOutlined /> : <DownOutlined />}
          </FlexLaguage>
        </Button>
      </DropdownLaguage>
    </div>
  );
};
