import React, { useCallback } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';

import {
  ADD_STORY_LOCALLY,
  BLUR_STORY_LOCALLY,
  COMPLETE_STORY_LOCALLY,
  DELETE_STORY_LOCALLY,
  FOCUS_STORY_LOCALLY,
  UPDATE_STORY_LOCALLY,
  useStoreContext,
} from '../store';
import { Story } from '../typings/story';
import { createNewStory } from '../utils/story';
import StoryItem from './StoryItem';
import { Stage } from '../typings/stage';
import { Board } from '../typings/board';
import { useTranslation } from 'react-i18next';

type Props = {
  isDragging: boolean;
  provided: DraggableProvided;
  readOnly?: boolean;
  story: Story;
  isBoldColors: boolean;
  allActivesStages: Stage[];
  allActivesBoards: Board[];
};

export const StoryItemContainer: React.FC<Props> = props => {
  const { state, dispatch } = useStoreContext();
  const { isBoldColors } = props; //questionable
  const { t } = useTranslation();

  const handleAdd = useCallback(
    (stageId: string, index: number) => {
      const newStory = createNewStory(stageId, {
        priority: index,
        color: props.story.color,
      });
      dispatch({
        payload: newStory,
        type: ADD_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskCreated',
      });
    },
    [dispatch],
  );
  const handleBlur = useCallback(
    (story?: Story) => {
      dispatch({
        payload: {},
        type: BLUR_STORY_LOCALLY,
      });
      if (story) {
        dispatch({
          payload: {
            ...story,
            updatedAt: new Date(),
          },
          type: UPDATE_STORY_LOCALLY,
        });
        ReactGA.event({
          category: 'task',
          action: 'taskUpdated',
        });
      }
    },
    [dispatch],
  );
  const handleMoveCard = useCallback(
    (story: Story) => {
      dispatch({
        payload: {
          ...story,
          updatedAt: new Date(),
        },
        type: UPDATE_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskMoved',
      });
    },
    [],
  );
  const handleChange = useCallback(
    (story: Story) => {
      if (
        story.title === props.story.title &&
        story.priority === props.story.priority &&
        story.color === props.story.color &&
        story.deadlineAt === props.story.deadlineAt &&
        story.createdAt &&
        story.updatedAt

      ) {
        return;
      }
      const timestamp = new Date();
      dispatch({
        payload: {
          ...story,
          createdAt: story.createdAt || timestamp,
          updatedAt: timestamp,
        },
        type: UPDATE_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskUpdated',
      });
    },
    [props.story, dispatch],
  );
  const handleDelete = useCallback(
    (story: Story) => {
      dispatch({
        payload: story,
        type: DELETE_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskDeleted',
      });
    },
    [dispatch],
  );

  const handleComplete = useCallback(
    (story: Story) => {
      dispatch({
        payload: story,
        type: COMPLETE_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskCompleted',
      });
    },
    [dispatch],
  );

  const handleFocus = useCallback(
    (story: Story) => {
      dispatch({
        type: FOCUS_STORY_LOCALLY,
        payload: story,
      });
    },
    [dispatch],
  );

  return (
    <StoryItem
      {...props}
      t={t}
      handleMoveCard={handleMoveCard}
      isBoldColors={isBoldColors}
      isFocus={state.focusStoryId === props.story.id}
      onAdd={handleAdd}
      onBlur={handleBlur}
      onChange={handleChange}
      onDelete={handleDelete}
      onComplete={handleComplete}
      onFocus={handleFocus}
    />
  );
};
