import { faArrowLeft, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Testimonial {
  text: string,
  username: string,
  rating: number,
  platform: string,
  date: Date
}

interface ImageTestimonialProps {
  testimonials: Testimonial[],
  images: string[]
}

const StarRating = (props: { rating: number }) => (
  <div className="stars" style={{ display: 'flex', justifyContent: 'center' }}>
    {Array.from(
      {
        length: props.rating,
      },
      (_, idx) => (
        <FontAwesomeIcon
          key={idx}
          icon={faStar}
          color="#fff"
          style={{ fontSize: '10px' }}
        />
      ),
    )}
  </div>
)

const NavArrowButton = (props: { type: 'next' | 'prev', onClick: () => void }) => (
  <ArrowButton
    onClick={props.onClick}
  >
    {
      props.type === 'next'
        ?
        <FontAwesomeIcon
          icon={faArrowRight}
          color="#fff"
          style={{ fontSize: '15px' }}
        />
        :
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="#fff"
          style={{ fontSize: '15px' }}
        />
    }
  </ArrowButton>
)

const ArrowButton = styled.div`
  color: var(--base-white, #ffffff);
  border: 1px solid var(--base-white, #ffffff);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 10px 0 10px;
`

export const ImageTestimonial = (props: ImageTestimonialProps) => {
  const [currentImage, setCurrentImage] = useState<number>(0)
  const [currentTestimonial, setCurrentTestimonial] = useState<number>(0)
  const { t } = useTranslation();

  const handleClickNext = () => {
    if (currentImage + 1 === props.images.length) {
      setCurrentImage(0)
    } else {
      setCurrentImage(currentImage + 1)
    }

    if (currentTestimonial + 1 === props.testimonials.length) {
      setCurrentTestimonial(0)
    } else {
      setCurrentTestimonial(currentTestimonial + 1)
    }
  }

  const handleClickPrev = () => {
    if (currentImage === 0) {
      setCurrentImage(props.images.length - 1)
    } else {
      setCurrentImage(currentImage - 1)
    }

    if (currentTestimonial === 0) {
      setCurrentTestimonial(props.testimonials.length - 1)
    } else {
      setCurrentTestimonial(currentTestimonial - 1)
    }
  }

  return (
    <TestimonialImage
      imageurl={props.images[currentImage] || ''}
    >
      <TestimonialContainer>
        <TestimonialText>“{t(props.testimonials[currentTestimonial].text)}”</TestimonialText>

        <TestimonialBottomContainer>
          <div>
            <TestimonialUserName>{props.testimonials[currentTestimonial].username}</TestimonialUserName>
            <TestimonialDate>{props.testimonials[currentTestimonial].date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</TestimonialDate>
          </div>

          <div className='d-flex flex-column justify-content-center'>
            {/* <StarRating rating={props.testimonials[currentTestimonial].rating} /> */}
            <div className='d-flex'>
              <NavArrowButton type='prev' onClick={handleClickPrev} />
              <NavArrowButton type='next' onClick={handleClickNext} />
            </div>
          </div>
        </TestimonialBottomContainer>
      </TestimonialContainer>
    </TestimonialImage>
  )
}

export default ImageTestimonial

const TestimonialImage = styled.div<{ imageurl: string }>`
  width: 50vw;
  height: 100%;
  background-image: url(${props => props.imageurl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: background-image .7s;
`

const TestimonialContainer = styled.div`
  background-color: rgba(200,200,200, 0.3);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.50);
  margin: 40px;
  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const TestimonialText = styled.div`
  color: var(--base-white, #ffffff);
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 120% */
  letter-spacing: -0.6px;
`

const TestimonialBottomContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 32px;
`

const TestimonialUserName = styled.div`
  color: var(--base-white, #ffffff);
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 111.111% */
  letter-spacing: -0.72px;
`

const TestimonialDate = styled.div`
  color: var(--base-white, #ffffff);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`