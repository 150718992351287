import React, { PropsWithChildren, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useMounted } from '../hooks/useMounted';

type Props = {
  panelOpen: boolean;
  sidebarExpanded: boolean;
  handleClickCloseButton?: () => void;
};

const PANEL_OPEN_ANIMATION_TIME = 400;

export const AccountPanelWrapper = (props: PropsWithChildren<Props>) => {
  const {
    panelOpen,
    sidebarExpanded,
    children,
    handleClickCloseButton,
  } = props;

  const [panelOpenState, setPanelOpenState] = useState(false);
  const [lazyLoadPanel, setlazyLoadPanel] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const isMounted = useMounted();

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (panelOpen === false) {
      // If we are closing the panel, delay this to allow it to do hide animation before unloading
      setShowCloseIcon(panelOpen);
      setPanelOpenState(panelOpen);
      setTimeout(() => {
        if (isMounted()) {
          setlazyLoadPanel(panelOpen);
        }
      }, PANEL_OPEN_ANIMATION_TIME);
    } else {
      // If we are opening the panel, load the panel off screen before animating it into view
      setlazyLoadPanel(panelOpen);
      setTimeout(() => {
        if (isMounted()) {
          setPanelOpenState(panelOpen);
        }
      }, 20);
      setTimeout(() => {
        if (isMounted()) {
          setShowCloseIcon(panelOpen);
        }
      }, PANEL_OPEN_ANIMATION_TIME);
    }
  }, [panelOpen]);

  return lazyLoadPanel ? (
    <div
      className={`account-panel-wrapper ${
        sidebarExpanded ? 'expanded' : 'collapsed'
      }`}
      style={{
        left: panelOpenState ? '0' : '-100vw',
        transition: `left ${PANEL_OPEN_ANIMATION_TIME}ms ease-in-out`,
      }}>
      <FontAwesomeIcon
        icon={faTimesCircle}
        size={'3x'}
        className="account-panel-close-icon"
        style={{
          opacity: showCloseIcon ? 1 : 0,
        }}
        onClick={() => {
          handleClickCloseButton && handleClickCloseButton();
        }}
      />
      {children}
    </div>
  ) : (
    <></>
  );
};
