import React, { ChangeEvent, Component } from 'react';
import styled from 'styled-components';

import { colors, sizes } from '../theme';
import { EmojiPicker, EmojiTarget } from './EmojiPicker';

type Props = {
  autoFocus: boolean;
  rows: number;
  target: EmojiTarget;
  value: string;
  color: string;
  style?: any;
  onChange: (v: string) => void;
};

type State = {
  emojiSearch: string;
};

export class Textarea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { emojiSearch: '' };
  }

  handleEmojiSelect = (emoji: string) => {
    const { value } = this.props;
    const lastColonIndex = value.lastIndexOf(':');
    const newValue = value.slice(0, lastColonIndex) + emoji;

    this.props.onChange(newValue);
    this.setState({
      emojiSearch: '',
    });
  };

  handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    this.props.onChange(value);
    const lastColonIndex = value.lastIndexOf(':');
    if (lastColonIndex !== -1) {
      this.setState({
        emojiSearch: value.slice(lastColonIndex + 1),
      });
    } else {
      this.setState({
        emojiSearch: '',
      });
    }
  };

  render() {
    const { target } = this.props;
    const { emojiSearch } = this.state;
    let pickerStyle = {
      position: 'absolute',
      left: '100%',
      top: -sizes.story.padding,
    };

    return (
      <Container style={this.props.style}>
        <TextArea {...this.props} onFocus={this.handleTextChange} onChange={this.handleTextChange} style={{ color: 'rgba(0, 0, 0, 0.7)' }} />
        {emojiSearch && (
          <EmojiPicker
            search={emojiSearch}
            style={pickerStyle}
            target={target}
            onSelect={this.handleEmojiSelect}
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
`;

const TextArea = styled.textarea`
  background-color: transparent;
  border: 0px;
  color: ${colors.white};
  margin: 0px;
  font-size: 1em;
  font-family: sans-serif;
  outline: none;
  overflow: auto;
  padding: ${sizes.story.padding}px;
  padding-top: 5px;
  resize: none;
  width: ${sizes.stage.width -
  2 * sizes.story.padding -
  2 * sizes.story.margin}px;
`;
