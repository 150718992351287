import React, { Component } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { colors, sizes, borderRadius } from '../theme';
import { Stage } from '../typings/stage';
import { Story } from '../typings/story';
import { StoryItemContainer } from './StoryItem.container';
import { PeriodCompletedCards, StoryViewModel } from './ViewModels';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { isNil } from 'lodash';
import CompletedStoryItem from './CompletedStoryItem';
import { Board } from '../typings/board';

export type Props = {
  accountId: string;
  autoFocusStoryId?: string;
  ignoreContainerClipping?: boolean;
  isDropDisabled?: boolean;
  readOnly?: boolean;
  style?: Object;
  t?: any;
  stage: Stage;
  stories: Story[];
  isBoldColors: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  allActivesStages: Stage[];
  allActivesBoards: Board[];

  onAccountClick?: () => void;
  onAddStoryClick?: () => void;
  onStoryCountChange?: (value: number) => void;
  handleAccountClick?: () => void;
};

type State = {
  accountId: string;
  autoFocusStoryId?: string;
  isEditMode: boolean;
  message: any;
};

class StoryList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accountId: this.props.accountId,
      autoFocusStoryId: this.props.autoFocusStoryId,
      isEditMode: false,
      message: '',
    };
  }
  b: number = 0;
  static getDerivedStateFromProps(props: Props) {
    const { accountId, autoFocusStoryId, stories } = props;
    return {
      accountId,
      autoFocusStoryId,
      viewModel: stories,
    };
  }

  //todo pri2: these events should be on the item instead of the list right?
  handleClick = (story: StoryViewModel) => {
    if (this.props.readOnly) {
      return;
    }
    this.setState({
      autoFocusStoryId: story.id,
      isEditMode: true,
    });
  };
  componentDidMount() {
    // const message =
    // this.setState({ message });
  }
  renderStories = (dropProvided: DroppableProvided, readOnly: boolean) => {
    const { stories, isBoldColors } = this.props;
    let data4: any = localStorage.getItem('stories')
    let newData4 = JSON.parse(data4)
    this.b = newData4 + stories.length;

    localStorage.setItem('stories', JSON.stringify(this.b))

    return (
      <Container>
        <DropZone ref={dropProvided.innerRef}>
          {stories.filter(x => !x.completedAt).map((story, index) => (
            <Draggable
              draggableId={story.id}
              index={index}
              isDragDisabled={readOnly}
              key={story.id}>
              {(
                dragProvided: DraggableProvided,
                dragSnapshot: DraggableStateSnapshot,
              ) => (
                <StoryItemContainer
                  isDragging={dragSnapshot.isDragging}
                  key={story.id}
                  provided={dragProvided}
                  readOnly={readOnly}
                  story={story}
                  isBoldColors={isBoldColors}
                  allActivesStages={this.props.allActivesStages}
                  allActivesBoards={this.props.allActivesBoards}
                />
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    );
  };

  renderStoriesCompleted = () => {
    const { stories, isBoldColors } = this.props;
    const hasAnyValidStory = stories.some((t) => isNil(t.completedAt));
    const hasAnyStoryCompleted = stories.some((t) => !isNil(t.completedAt));

    if (!hasAnyStoryCompleted) return null;

    return (
      <>
        {hasAnyValidStory === true && <div style={{ height: 2, backgroundColor: 'black', marginBottom: 8 }} />}
        <Container>
          <DropZone>
            {stories.filter(x => x.completedAt).map((story, index) => (
              <CompletedStoryItem
                isDragging={false}
                key={story.id}
                readOnly={true}
                story={story}
                isBoldColors={isBoldColors}
              />
            ))}
          </DropZone>
        </Container>
      </>
    );
  };

  render() {
    const {
      ignoreContainerClipping,
      isDropDisabled,
      stage,
      style,
      readOnly,
      onAddStoryClick,
    } = this.props;
    const { message } = this.state;

    return (
      <ColumnContainer>
        <Droppable
          droppableId={stage.id}
          ignoreContainerClipping={ignoreContainerClipping}
          isDropDisabled={isDropDisabled}
          type="STORY">
          {(
            dropProvided: DroppableProvided,
            dropSnapshot: DroppableStateSnapshot,
          ) => (
            <div style={style} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
              {this.renderStories(dropProvided, !!readOnly)}
            </div>
          )}
        </Droppable>
        {this.props.isCompletedCards && this.renderStoriesCompleted()}
        {!readOnly && (
          <Link
            isVisible={stage.createdAt !== undefined}
            onClick={onAddStoryClick}
          >
            {t('Board.AddTaskCard')}
          </Link>
        )}
      </ColumnContainer>
    );
  }
}
export default withTranslation()(StoryList as any);

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s ease, opacity 0.1s ease;
  user-select: none;
  border-left: 1px solid #E7E7E7;
  border-right: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
  padding: ${sizes.story.margin}px;
  background-color: white;
`;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  /* not relying on the items for a margin-bottom
  as it will collapse when the list is empty */
  margin-bottom: 8px;
`;

const Container = styled.div``;

type LinkProps = {
  isVisible: boolean;
};

const Link = styled.a<LinkProps>`
  text-align: center;
  border: 1px solid #E7E7E7;
  border-radius: ${borderRadius}px;
  font-weight: bold;
  padding: 7px;
  visibility: ${({ isVisible }) => (isVisible ? '' : 'hidden')};
  transition: visibility 0.1s ease;
  color: ${colors.black};
  &:hover {
    cursor: pointer;
  }
`;
