export interface Plan {
  Id: string;
  CreatedAt?: string;
  UpdatedAt?: string;
  DeletedAt?: string;
  Price: string;
  Price_id?: string;
  Title: string;
}

export interface PlanAPIResponse {
  isKanbanaPremiumEnabled: 0 | 1,
  subscriptionType: string,
  subscriptionStatus: string,
  planList: Plan[]
}

export const freePlan: Plan = {
  Id: 'FREE',
  Title: 'Free',
  Price: 'Free',
};