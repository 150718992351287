import { useEffect, useMemo } from 'react';
import Pusher from 'pusher-js';

import { useStoreContext, useSynchronize } from '../store';
import { Trigger } from '../typings/synchronize';
import { usePrevious } from './usePrevious';

export const usePusher = () => {
  const store = useStoreContext();
  const synchronize = useSynchronize();

  const previousAccountId = usePrevious(store.state.accountId) as
    | string
    | undefined;

  const pusher = useMemo(
    () =>
      new Pusher('90f7a47c912844142a03', {
        cluster: 'eu',
      }),
    [],
  );

  useEffect(() => {
    const accountId = store.state.accountId;
    const syncId = sessionStorage.getItem('com.kanbana.syncId');
    if (!syncId) {
      return;
    }
    if (!previousAccountId && accountId) {
      const channel = pusher.subscribe(accountId);
      channel.bind('please-sync', async (data: { SyncId?: string }) => {
        if (syncId !== data.SyncId) {
          await synchronize({
            accountId,
            trigger: Trigger.User,
            pusher: 1,
          });
        }
      });
    }
    if (previousAccountId && !accountId) {
      pusher.unbind_all();
      pusher.unsubscribe(previousAccountId);
    }
  }, [previousAccountId, pusher, store.state.accountId]);
};
