import { Stage } from '../typings/stage';
import { State } from '../typings/state';
import { Story } from '../typings/story';
import { PeriodCompletedCards } from '../board/ViewModels';
import { lastMonths, lastDays, lastYears } from '../utils/date';

export const getBoards = (
  boards: State['boards'],
  filterResults: boolean = true,
) => {
  if (!boards) {
    return [];
  }
  if (!filterResults) {
    return Object.values(boards).sort((a, b) => a.priority - b.priority);
  }
  return Object.values(boards)
    .filter(board => !board.deletedAt)
    .sort((a, b) => a.priority - b.priority);
};

// CONVENTION: Have to use computed functions to access state data in container components
export const getBoardStages = (
  stages: State['stages'],
  stagesIndex: State['stagesIndex'],
  boardId?: string,
  filterResults: boolean = true,
) => {
  if (!boardId || !stages || !stagesIndex || !stagesIndex[boardId]) {
    return [];
  }
  const stageIds = Object.keys(stagesIndex[boardId]);
  const boardStages: Stage[] = [];
  for (let i = 0; i < stageIds.length; i++) {
    const stageId = stageIds[i];
    if (!stages[stageId]) {
      continue;
    }
    boardStages.push(stages[stageId]);
  }
  if (!filterResults) {
    return boardStages;
  }

  const sortedStages = boardStages
    .filter(stage => !stage.deletedAt)
    .sort((a, b) => a.priority - b.priority);
  if (!sortedStages.length) {
    return [];
  }

  sortedStages[sortedStages.length - 1].isLast = true;
  return sortedStages;
};

export const getStageStories = (
  stories: State['stories'],
  storiesIndex: State['storiesIndex'],
  stage?: Stage,
  filterResults: boolean = true,
  isCompletedCards: boolean = false,
  selectedPeriodCompletedCards: PeriodCompletedCards = {
    period: 1,
    periodType: 'Days'
  },
) => {
  const stageId = stage?.id;
  if (!stageId || !stories || !storiesIndex || !storiesIndex[stageId]) {
    return [];
  }
  const storyIds = Object.keys(storiesIndex[stageId]);
  const stageStories: Story[] = [];
  for (let i = 0; i < storyIds.length; i++) {
    const stageId = storyIds[i];
    if (!stories[stageId]) {
      continue;
    }
    stageStories.push(stories[stageId]);
  }
  if (!filterResults) {
    return stageStories;
  }

  if (isCompletedCards) {
    let stages = stageStories
      .filter(story => !story.deletedAt && !story.completedAt! && !story.hidden)
      .sort((a, b) => a.priority - b.priority);

    if (selectedPeriodCompletedCards.periodType === 'Days') {
      stages = [...stages, ...stageStories.filter(story => !story.deletedAt && story.completedAt && lastDays(story.completedAt!, selectedPeriodCompletedCards.period) && !story.hidden).sort((a, b) => a.priority - b.priority)];
    } else if (selectedPeriodCompletedCards.periodType === 'Months') {
      stages = [...stages, ...stageStories.filter(story => !story.deletedAt && story.completedAt && lastMonths(story.completedAt!, selectedPeriodCompletedCards.period) && !story.hidden).sort((a, b) => a.priority - b.priority)];
    } else if (selectedPeriodCompletedCards.periodType === 'Years') {
      stages = [...stages, ...stageStories.filter(story => !story.deletedAt && story.completedAt && lastYears(story.completedAt!, selectedPeriodCompletedCards.period) && !story.hidden).sort((a, b) => a.priority - b.priority)];
    }

    return stages;
  }

  return stageStories
    .filter(story => !story.deletedAt && !story.completedAt! && !story.hidden)
    .sort((a, b) => a.priority - b.priority);
};
