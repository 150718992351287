// @flow
export const colors = {
  black: '#4d4d4d',
  blue: {
    deep: 'rgb(0, 121, 191)',
    light: 'lightblue',
    lighter: '#d9fcff',
  },
  border: {
    lightGrey: '#e3e6ec',
    grey: '#848688',
  },
  shadow: 'rgba(0,0,0,0.2)',
  focusOutline: '#E2E4E6',
  darkgrey: '#777777',
  green: 'rgb(185, 244, 188)',
  white: 'white',
  lightgrey: '#F8FAFB',
  purple: 'rebeccapurple',
  stage: {
    backgroundColor: '#ffffff',
    hover: '#ffffff',
    editModeBackgroundColor: '#ffffff'
  },
  defaultColor: "#64B5F6",
  taskOptions: ['#F9F7F7', '#EF5350', '#FF6D00', '#FEFE50', '#01FB2B', '#64B5F6', '#D8A5EA', '#748182', '#B63028', '#CB4A12', '#EF9423', '#2BC96A', '#1C977A', '#2C71AE', '#8430A1', '#6B4030'],
  taskOptionsBold: ['#01B6E9', '#FBC30E', '#6DAD35', '#E8731E', '#888888', '#E90101', '#95520B', '#A335AD', '#195893', '#454545'],
  taskOptionsLight: ['#DEF5FF', '#FFFEEA', '#F3FAEB', '#FFEDE0', '#F6F6F6', '#FFDEDE', '#FFF0DF', '#FDF4FF', '#EEF0FD', '#E7E7E7'],
  taskOptionsLightBorder: ['#B6EEFF', '#FFEE49', '#CBEAAC', '#FFD6B9', '#D1D1D1', '#FFB9B9', '#EACBAA', '#F6D0FE', '#C4C9EC', '#B0B0B0'],
  //blackOption:['#F9F7F7','#01FB2B','#FEFE50','#AAAAAA','#0AFF33','#FFFF8D'],
  task: {
    red: '#bf1503',
    orange: '#f66e00',
    grey: '#F9F7F7',
    blue: '#01B6E9',
  },
  menu: {
    background: '#dddddd',
    link: '#333333',
  },
  buttons: {
    default: {
      active: '#D45100',
      highlighted: '#B33D0E',
    },
    delete: {
      active: 'rgba(255,255,255,0.80)',
      highlighted: '#ffffff',
      highlightedblack: '#4d4d4d',
    },
  },
};

export const borderRadius = 7;

export const sizes = {
  stage: {
    width: 240,
    margin: 8,
    titlePaddingLeft: 15,
  },
  story: {
    padding: 10,
    margin: 8,
  },
};
