import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';
import * as uuid from 'uuid';
import { TemplateModal } from '../board/TemplateModal';
import { useTranslation } from 'react-i18next';
import { TEMPLATES } from '../utils/template';
import { forEach } from 'lodash';
import service from '../api/Service';

import {
  ADD_BOARD_LOCALLY,
  SET_ACTIVE_BOARD,
  DELETE_BOARD_LOCALLY,
  UPDATE_BOARD_LOCALLY,
  UPDATE_BOARDS_LOCALLY,
  getBoards,
  useStoreContext,
  useSynchronize,
  ADD_STAGE_LOCALLY,
} from '../store';
import { LocalStorage } from '../typings/app';
import { Board, CopyBoardResponse } from '../typings/board';
import { Stage } from '../typings/stage';
import { Trigger } from '../typings/synchronize';
import Sidebar, { Props as SidebarProps } from './Sidebar';
import { arrayMove } from '../utils/array';
import { updateBoardPriorities } from '../utils/board';
import { AddBoardModal } from '../board/AddBoardModal';
import { PremiumModal } from '../components/PremiumModal';
import { isFreeAccount } from '../utils/users';
import { isNil } from 'lodash';
import { FastSpringContext } from '../store/FastSpringContext';
import { EnjoyFullExpModal } from '../board/EnjoyFullExpModal';
import { LogEvent } from '../utils/analytics';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';

type Props = Omit<
  SidebarProps,
  | 'accountId'
  | 'activeBoardId'
  | 'addBoardModalVisible'
  | 'boards'
  | 'onAddBoardClick'
  | 'onAddBoardsClick'
  | 'onAddBoardTemplateClick'
  | 'onBoardCopied'
  | 'onBoardDelete'
  | 'onBoardDragEnd'
  | 'onBoardSelect'
  | 'onBoardSyncClick'
  | 'onBoardUpdate'
  | 'completedStories'
> & {};

export const SidebarContainer: React.FC<Props> = ({ ...props }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { state, dispatch } = useStoreContext();
  const [goPremiumVisible, setGoPremiumVisible] = useState(false);
  const synchronize = useSynchronize();
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const { t } = useTranslation();
  const [openModal, setOpemModal] = useState(false);
  const [completePayment, setCompletePayment] = useState(false);
  const { addPlans } = React.useContext(FastSpringContext);
  const accountId = isNil(localStorage.getItem('AccountInfo')) ? '' : JSON.parse(localStorage.getItem('AccountInfo') || '')?.Id ?? '';

  useEffect(() => {
    const fastSpringCallBack = (data: any) => {
      /* Iterate over payload to find whatever information is relevant
        For this example we'll want to display localized price of
        the product 'demo-product-4'
        */
      if (data && data.groups) {
        const items: any[] = [];
        // @ts-ignore
        data.groups.forEach((group) => {
          if (group.items && Array.isArray(group.items)) {
            group.items.forEach((item: any) => {
              items.push(item);
            });
          }
        });
        addPlans(items);
      }
    };

    const successFastSpring = (hasApproved: boolean) => {
      if (hasApproved) {
        // setCompletePayment(() => true);
        const accountInfo: any = JSON.parse(localStorage.getItem('AccountInfo') || '');
        if (!isNil(accountInfo)) {
          accountInfo.subcode = 'h' + accountInfo.Id + 'qp';
          accountInfo.IsKanbanaPremiumEnabled = true;
          localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
        }
        localStorage.setItem('CompletePaymentModal', "true");

        if (isDesktopApp()) {
          // Check if "Premium" event has been sent
          let premium_key = "td_kanbana_event_premium";
          let premium_check = localStorage.getItem(premium_key);
          if (!premium_check) {
            // If we have not sent the event, send it now
            var distributor = localStorage.getItem("kanbana_distributor") ?? "Unknown";
            fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Premium");
            localStorage.setItem(premium_key, "sent");
          }
        }

        setTimeout(() => window.location.reload(), 1500);
      }
    }

    const popupClosedFastSpringCallBack = (data: any) => {
      if (!isNil(data)) {
        const orderId = data.id;
        service.saveReceipt({
          accountId: accountId as string,
          receipt: JSON.stringify(data),
          isIos: false,
          platform: "FASTSPRING",
          paymentKey: orderId,
          success: successFastSpring,
        });
        localStorage.setItem('OpenPaymentModal', "true");
      }
    }

    // Add SBL script programatically
    const addSBL = () => {
      const scriptId = "fsc-api";
      const existingScript = document.getElementById(scriptId);
      const isDevEnv = window.location.origin.includes('localhost') || window.location.origin.includes('uatweb.kanbana');
      const url = isDevEnv ? 'kanbana.test.onfastspring.com/popup-kanbana' : 'kanbana.onfastspring.com/popup-defaultB2B';

      if (!existingScript) {
        const storeFrontToUse = url;
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.id = scriptId;
        script.src =
          "https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js";
        script.dataset.storefront = storeFrontToUse;
        // Make sure to add callback function to window so that the DOM is aware of it
        // @ts-ignore
        window.fastSpringCallBack = fastSpringCallBack;
        // @ts-ignore
        window.popupClosedFastSpringCallBack = popupClosedFastSpringCallBack;
        script.setAttribute("data-data-callback", "fastSpringCallBack");
        script.setAttribute("data-popup-closed", "popupClosedFastSpringCallBack")

        document.body.appendChild(script);
      }
    };
    addSBL();
  }, []);

  useEffect(() => {
    localStorage.setItem('OpenPaymentModal', "false");
    localStorage.setItem('CompletePaymentModal', "false");
  }, []);

  const hideModals = () => {
    setGoPremiumVisible(false);
    setModalVisible(false);
  };

  useEffect(() => {
    const value = localStorage.getItem('OpenPaymentModal');
    if (value === "true") {
      setGoPremiumVisible(() => false);
      setModalVisible(() => false);
    }
    setOpemModal(() => value === "false" ? false : true);
  }, [localStorage.getItem('OpenPaymentModal'), setGoPremiumVisible, setModalVisible]);

  useEffect(() => {
    const value = localStorage.getItem('CompletePaymentModal');
    if (value === "true") {
      setCompletePayment(() => true);
      setTimeout(() => window.location.reload(), 1500);
    }
  }, [localStorage.getItem('CompletePaymentModal'), setCompletePayment])

  const boards = useMemo(() => getBoards(state.boards), [state.boards]);
  const activeStages = useMemo(() => {
    if (state.activeBoardId) {
      return Object.values(state.stages || {}).filter(
        stage => stage.boardId === state.activeBoardId && !stage.deletedAt,
      );
    } else {
      return [];
    }
  }, [state.stages, state.activeBoardId]);
  const activeStories = useMemo(() => {
    return Object.values(state.stories || {}).filter(
      story =>
        activeStages.some(stage => story.stageId === stage.id) &&
        !story.completedAt &&
        !story.deletedAt,
    );
  }, [state.stories, state.activeBoardId]);

  const completedStories = useMemo(() => {
    return Object.values(state.stories || {}).filter(
      story =>
        activeStages.some(stage => story.stageId === stage.id) &&
        story.completedAt
    );
  }, [state.stories, state.activeBoardId]);

  const handleAddBoardCancel = useCallback(() => {
    LogEvent('addBoardCanceled');
    setModalVisible(false);
  }, []);

  const handleAddBoardClick = useCallback(() => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    if (!isFreeAccount() || boards.filter((t) => isNil(t.deletedAt)).length === 0) {
      LogEvent('addBoardClicked');
      setModalVisible(true);
    } else {
      setGoPremiumVisible(true);
    }
  }, []);

  const handleAddBoardTemplateClick = useCallback(() => {
    setTemplateModalVisible(true);
  }, []);
  const handleAddBoardsClick = useCallback(() => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    if (isFreeAccount() && boards.filter(t => isNil(t.deletedAt)).length > 0) {
      setGoPremiumVisible(true);
      return;
    }

    if (!state.accountId) {
      return;
    }

    const timestamp = new Date();
    const id = uuid.v1();

    const newBoard: Board = {
      accountId: state.accountId,
      id,
      priority: 0,
      title: t(`Template.${props.activeTemplateId}`),
      createdAt: timestamp,
      updatedAt: timestamp,
    };

    const columns = TEMPLATES.find(x => x.id === props.activeTemplateId)!.columns;

    dispatch({
      type: ADD_BOARD_LOCALLY,
      payload: newBoard,
    });

    forEach(columns, (column) => {
      dispatch({
        type: ADD_STAGE_LOCALLY,
        payload: {
          id: uuid.v1(),
          boardId: id,
          isLast: true,
          priority: column.priority,
          storyLimit: column.storyLimit,
          title: t(`Template.${column.title}`),
          createdAt: timestamp,
          updatedAt: timestamp,
        },
      });
    })

    dispatch({
      type: SET_ACTIVE_BOARD,
      payload: id,
    });

    LogEvent('addBoardTemplateSucceeded');
    props.handleShowMyAccountPanel(false);
    setModalVisible(false);
  }, [props.activeTemplateId]);
  const handleSelectBoardTemplate = useCallback((template: string) => {
    dispatch({
      type: SET_ACTIVE_BOARD,
      payload: template,
    });
    setTemplateModalVisible(false);
  }, [])

  const handleAddBoardSubmit = useCallback(
    (title: string) => {
      if (!state.accountId) {
        return;
      }

      const timestamp = new Date();
      const id = uuid.v1();
      const newBoard: Board = {
        accountId: state.accountId,
        id,
        priority: 0,
        title,
        createdAt: timestamp,
        updatedAt: timestamp,
        // subscription: undefined,
      };
      const newStage1: Stage = {
        id: uuid.v1(),
        boardId: id,
        isLast: true,
        priority: 0,
        storyLimit: 0,
        title: 'To Do List',
        createdAt: timestamp,
        updatedAt: timestamp,
      };
      const newStage2: Stage = {
        id: uuid.v1(),
        boardId: id,
        isLast: true,
        priority: 0,
        storyLimit: 0,
        title: 'Doing Today',
        createdAt: timestamp,
        updatedAt: timestamp,
      };
      const newStage3: Stage = {
        id: uuid.v1(),
        boardId: id,
        isLast: true,
        priority: 0,
        storyLimit: 0,
        title: 'Done!',
        createdAt: timestamp,
        updatedAt: timestamp,
      };
      dispatch({
        type: ADD_BOARD_LOCALLY,
        payload: newBoard,
      });
      dispatch({
        type: ADD_STAGE_LOCALLY,
        payload: newStage1,
      });
      dispatch({
        type: ADD_STAGE_LOCALLY,
        payload: newStage2,
      });
      dispatch({
        type: ADD_STAGE_LOCALLY,
        payload: newStage3,
      });

      dispatch({
        type: SET_ACTIVE_BOARD,
        payload: id,
      });
      LogEvent('addBoardSucceeded');
      props.handleShowMyAccountPanel(false);
      setModalVisible(false);
    },
    [state.accountId, dispatch],
  );
  const handleBoardCopied = useCallback(
    async (response: CopyBoardResponse) => {
      if (!state.accountId) {
        return;
      }
      await synchronize(
        { accountId: state.accountId, trigger: Trigger.User, pusher: 5 },
        [],
        [],
        [],
      );
      dispatch({
        type: SET_ACTIVE_BOARD,
        payload: response.boardId,
      });
    },
    [state.accountId, dispatch, synchronize],
  );
  const handleBoardDelete = useCallback(
    (boardId: string) => {
      dispatch({
        type: DELETE_BOARD_LOCALLY,
        payload: boardId,
      });
    },
    [dispatch],
  );
  const handleBoardDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;
      if (!destination || destination?.index === source.index) {
        return;
      }
      const newBoards = arrayMove(boards, source.index, destination.index);
      const updatedBoards = updateBoardPriorities(newBoards);
      dispatch({
        type: UPDATE_BOARDS_LOCALLY,
        payload: {
          boards: updatedBoards,
        },
      });
      ReactGA.event({
        category: 'board',
        action: 'boardPriorityChanged',
      });
    },
    [boards, dispatch],
  );

  const handleBoardSelect = useCallback(
    (boardId: string) => {
      if (state.accountId) {
        dispatch({
          type: SET_ACTIVE_BOARD,
          payload: boardId,
        });
        const storage: LocalStorage = {
          accountId: state.accountId,
          lastBoardId: boardId,
        };
        localStorage.setItem(state.accountId, JSON.stringify(storage));
        hideModals();
      }
    },
    [state.accountId, dispatch],
  );
  const handleBoardUpdate = useCallback(
    (newBoard: Board) => {
      dispatch({
        type: UPDATE_BOARD_LOCALLY,
        payload: newBoard,
      });
    },
    [dispatch],
  );

  const handleSyncTrigger = useCallback(async () => {
    if (!state.accountId) {
      return;
    }
    await synchronize(
      { accountId: state.accountId, trigger: Trigger.User, pusher: 7 },
      [],
      [],
      [],
    );
  }, [state.accountId, synchronize]);

  return (
    <>
      <Sidebar
        accountId={state.accountId}
        activeBoardId={state.activeBoardId}
        addBoardModalVisible={modalVisible}
        boards={boards}
        boardCount={boards.length}
        storyCount={activeStories.length}
        onAddBoardClick={handleAddBoardClick}
        onAddBoardsClick={handleAddBoardsClick}
        onAddBoardTemplateClick={handleAddBoardTemplateClick}
        onBoardCopied={handleBoardCopied}
        onBoardDelete={handleBoardDelete}
        onBoardDragEnd={handleBoardDragEnd}
        onBoardSelect={handleBoardSelect}
        onBoardSyncClick={handleSyncTrigger}
        onBoardUpdate={handleBoardUpdate}
        {...props}
        onMenuToggle={(value: boolean) => props.onMenuToggle(value)}
        handleClickMyAccount={props.handleClickMyAccount}
        handleSetBackgroundFreezeState={props.handleSetBackgroundFreezeState}
        canCloseModal={goPremiumVisible === false}
        setGoPremiumModalVisible={setGoPremiumVisible}
        completedStories={completedStories}
      />
      <AddBoardModal
        open={modalVisible}
        onDismiss={handleAddBoardCancel}
        onSubmit={handleAddBoardSubmit}
      />
      {goPremiumVisible == true && <PremiumModal hidePremiumModal={hideModals} />}
      <TemplateModal
        onAddBoardClick={handleAddBoardClick}
        selectTemplate={handleSelectBoardTemplate}
        onCancel={() => setTemplateModalVisible(false)}
        open={templateModalVisible} />
      <EnjoyFullExpModal
        open={openModal}
        complete={completePayment}
      />
    </>
  );
};
