import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import { ModalContainer } from '../styling/Components';
import { colors } from '../theme';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '..';

type Props = {
  open?: boolean;
  onDismiss: () => void;
  onSubmit: (boardTitle: string) => void;
};

export const ResetPasswordModal: React.FC<Props> = ({
  open,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    const currentUser = auth.currentUser;
    if (!!currentUser?.email)
      sendPasswordResetEmail(auth, currentUser?.email)
        .then(() => {
          alert(t('modal.ChangePassword.alert'));
          onDismiss();
        })
        .catch(error => {
          alert(error);
        });
  }, [onSubmit]);

  return (
    <Modal destroyOnClose footer={false} onCancel={onDismiss} open={open}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <ModalContainer style={{ color: colors.black }}>
          <h2 style={{ marginBottom: 8 }}>{t('modal.ChangePassowrd.title')}</h2>
          <div>
            <p>{t('modal.ChangePassowrd.description')}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
                justifyContent: 'space-between',
              }}>
              <Button type="default" htmlType="reset" onClick={onDismiss}>
                {t('Text.Dismiss')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ maxWidth: '100%' }}>
                {t('Account.ResetPassword')}
              </Button>
            </div>
          </div>
        </ModalContainer>
      </Form>
    </Modal>
  );
};
