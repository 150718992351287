export default [
  {
    text: 'Testimonial.Text1',
    username: 'Boudude',
    rating: 5,
    platform: 'Kanbana for iOS',
    date: new Date('2/2/2023')
  },
  {
    text: 'Testimonial.Text2',
    username: 'GaryPol',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('3/21/2023')
  },
  {
    text: 'Testimonial.Text3',
    username: 'sussex123456',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('4/4/2023')
  },
  {
    text: 'Testimonial.Text4',
    username: 'Dr. H Kimpson',
    rating: 5,
    platform: 'Kanbana for iOS',
    date: new Date('5/19/2023')
  },
  {
    text: 'Testimonial.Text5',
    username: 'Tammy K.',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('6/11/2023')
  },
  {
    text: 'Testimonial.Text6',
    username: 'Helen C.',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('7/05/2023')
  },
  {
    text: 'Testimonial.Text7',
    username: 'FinnsMa',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('8/22/2023')
  },
  {
    text: 'Testimonial.Text8',
    username: 'Bob H.',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('9/16/2023')
  },
  {
    text: 'Testimonial.Text9',
    username: 'Giselle S.',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('10/11/2023')
  },
  {
    text: 'Testimonial.Text10',
    username: 'Leehwukkok LH',
    rating: 5,
    platform: 'Kanbana for Web',
    date: new Date('10/25/2023')
  }
]