import React from 'react';

import { App } from './App';
import { useDatabase } from './database/hooks';
import { useStoreContext, useSynchronize } from './store';
import { usePusher } from './hooks/usePusher';
import { IntercomProvider } from 'react-use-intercom';

export const AppContainer: React.FC = () => {
  const database = useDatabase();
  const store = useStoreContext();
  const syncOperation = useSynchronize();
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

  usePusher();

  return (
    <IntercomProvider autoBoot appId={intercomAppId ?? ''}>
      <App database={database} store={store} synchronize={syncOperation} />;
    </IntercomProvider>
  )
};
