export const changeLightness = (input: string, amount: number) => {
  /*
    This utility function changes the lightnes of a hex string
    
    input: 6 decimal hex color string with leading # (example: '#a24f8b')
    amount: number between 0 and 2. <1 will darken and >1 will lighten

    Note: conversion logic from https://css-tricks.com/converting-color-spaces-in-javascript/
  */

  let r = parseInt(input.slice(1, 3), 16)
  let g = parseInt(input.slice(3, 5), 16)
  let b = parseInt(input.slice(5, 7), 16)

  r /= 255;
  g /= 255;
  b /= 255;

  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) {
    h = 0
  } else if (cmax == r) {
    h = ((g - b) / delta) % 6
  } else if (cmax == g) {
    h = (b - r) / delta + 2
  } else {
    h = (r - g) / delta + 4
  }

  h = Math.round(h * 60)

  if (h < 0) {
    h += 360
  }

  l = (cmax + cmin) / 2;

  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + amount * l + "%)";
}

export const BLUE_LIST = [
  '#01B6E9',
  '#195893',
  '#DEF5FF',
  '#EEF0FD',
];