import React, { Component } from 'react';
import { BaseEmoji, EmojiData, emojiIndex } from 'emoji-mart';
import styled from 'styled-components';

import { colors } from '../theme';
import { LogEvent } from '../utils/analytics';

export type EmojiTarget = 'board' | 'stage' | 'task' | string;

type Props = {
  search: string;
  style: any;
  target: EmojiTarget;

  onSelect: (emoji: string) => void;
};

type State = {
  emojis: EmojiData[];
};

const isBaseEmoji = (emoji: EmojiData): emoji is BaseEmoji => {
  if ((emoji as BaseEmoji).native) {
    return true;
  }
  return false;
};

export class EmojiPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      emojis: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { search } = this.props;
    if (search !== prevProps.search) {
      const emojis = emojiIndex.search(search);
      if (emojis) {
        this.setState({ emojis });
      }
    }
  }

  hanleEmojiSelect = (emoji: EmojiData) => {
    const { target, onSelect } = this.props;
    if (isBaseEmoji(emoji)) {
      onSelect(emoji.native);
    }
    if (target) {
      LogEvent('emojiInserted', { target });
    }
  };

  render() {
    const { style } = this.props;
    const { emojis } = this.state;

    if (!emojis.length) {
      return null;
    }

    return (
      <Container style={style}>
        {emojis.map(emoji => (
          <Item key={emoji.id} onClick={() => this.hanleEmojiSelect(emoji)}>
            <p>
              {isBaseEmoji(emoji) ? emoji.native : ''}
              {'  '}
              {emoji.colons}
            </p>
          </Item>
        ))}
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: ${colors.black};
  border: 1px solid ${colors.border.grey};
  border-radius: 7px;
  color: white;
  max-height: 168px;
  overflow: auto;
  z-index: 999;
`;

const Item = styled.div`
  cursor: pointer;
  padding: 8px 16px;

  &:hover {
    background-color: #2b649e;
  }

  & > p {
    margin: 0;
    white-space: nowrap;
  }
`;
