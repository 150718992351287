import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    },
    hi: {
      translations: require('./locales/hin/translations.json')
    },
    fr: {
      translations: require('./locales/fr/translations.json')
    },
    de: {
      translations: require('./locales/ger/translations.json')
    },
    ja: {
      translations: require('./locales/jpn/translations.json')
    },
    ko: {
      translations: require('./locales/kor/translations.json')
    },
    pt: {
      translations: require('./locales/por/translations.json')
    },
    ru: {
      translations: require('./locales/rus/translations.json')
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es','hi', 'fr','de', 'ja', 'ko', 'pt', 'ru'];

export default i18n;