import React, { Component, RefObject } from 'react';
import ReactLinkify from 'react-linkify';
import styled from 'styled-components';

import { borderRadius, colors, sizes } from '../theme';
import { Story } from '../typings/story';
import { changeLightness } from '../utils/colors';

import { isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { BiAlarm } from "react-icons/bi";

type Props = {
  isDragging: boolean;
  isFocus?: boolean;
  readOnly?: boolean;
  story: Story;
  isBoldColors: boolean;
};

type State = {
  colorOptions: string[];
  story: Story;
  imageColor: string;
  completeCheck: boolean;
  deleteCheck: boolean;
};

export default class CompletedStoryItem extends Component<Props, State> {
  wrapperRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      colorOptions: props.isBoldColors
        ? colors.taskOptionsBold
        : colors.taskOptionsLight,
      story: props.story, // save it to state to reduce the impact on performance (just like before)
      imageColor: 'black',
      completeCheck: false,
      deleteCheck: false
    };
    this.wrapperRef = React.createRef();
  }

  // update component state when there are changes to the props
  static getDerivedStateFromProps(
    props: Props,
    state: State,
  ): Partial<State> | null {
    if (
      props.story.updatedAt?.toISOString() !==
      state.story.updatedAt?.toISOString()
    ) {
      return {
        story: props.story,
      };
    }
    return null;
  }

  render() {
    const {
      isDragging,
      isFocus,
      readOnly,
      isBoldColors,
    } = this.props;
    const { story } = this.state;

    return (
      <div
        style={{ opacity: story.completedAt == null ? 1 : 0.4, position: 'relative' }}
        ref={this.wrapperRef}>
        <Container>
          <Content
            style={{ color: `${getTextColor(isBoldColors)}` }}
            color={convertToDisplayColor(story.color, isBoldColors)}
            isbold={isBoldColors}
            isDragging={isDragging}
            readOnly={readOnly}>
            <ReactLinkify>
              {story.completedAt && (!isFocus && (!this.state.completeCheck && !isNil(story.completedAt) &&
                <LottieCheckStyled onClick={() => undefined}>
                  <div style={{
                    borderRadius: 18,
                    width: 15,
                    height: 15,
                    border: `1px solid ${isBoldColors ? 'white' : 'black'}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={isBoldColors ? "white" : 'black'}
                      style={{ fontSize: '10px' }}
                    />
                  </div>
                </LottieCheckStyled>
              ))}

              <Text style={{
                marginRight: '30px',
                fontSize: '14px',
                wordWrap: 'break-word'
              }}>
                {story.title}
                {!isNil(story.deadlineAt) &&
                  <DeadlineStyled>
                    <BiAlarm /> <span style={{ marginLeft: 3 }}>{dayjs(story.deadlineAt).format('MMM DD, YYYY')}</span>
                  </DeadlineStyled>
                }
              </Text>
            </ReactLinkify>
          </Content>
        </Container>
      </div>
    );
  }
}

const getTextColor = (isBoldColors: boolean): string => {
  return isBoldColors ? colors.white : colors.black;
};

const convertToStoredColor = (
  displayColor: string,
  isBoldColors: boolean,
): string => {
  for (let [k, v] of getColorStorageMap(isBoldColors)) {
    if (v === displayColor.toUpperCase()) {
      return k;
    }
  }
  return displayColor.toUpperCase();
};

const convertToDisplayColor = (
  storedColor: string,
  isBoldColors: boolean,
): string => {
  let colorMap = getColorDisplayMap(isBoldColors);
  return colorMap.get(storedColor.toUpperCase()) || storedColor.toUpperCase();
};

const getColorDisplayMap = (isBoldColors: boolean): Map<string, string> => {
  let colorMap = new Map<string, string>();
  if (isBoldColors) {
    colorMap.set('#F9F7F7', '#888888');
    colorMap.set('#EF5350', '#E8731E');
    colorMap.set('#FF6D00', '#E8731E');
    colorMap.set('#FEFE50', '#FBC30E');
    colorMap.set('#01FB2B', '#6DAD35');
    colorMap.set('#64B5F6', '#01B6E9');
    colorMap.set('#D8A5EA', '#A335AD');
    colorMap.set('#748182', '#454545');
    colorMap.set('#B63028', '#E90101');
    colorMap.set('#CB4A12', '#E90101');
    colorMap.set('#EF9423', '#FBC30E');
    colorMap.set('#2BC96A', '#6DAD35');
    colorMap.set('#1C977A', '#6DAD35');
    colorMap.set('#2C71AE', '#195893');
    colorMap.set('#8430A1', '#A335AD');
    colorMap.set('#6B4030', '#95520B');
  } else {
    colorMap.set('#F9F7F7', '#F6F6F6');
    colorMap.set('#EF5350', '#FFEDE0');
    colorMap.set('#FF6D00', '#FFEDE0');
    colorMap.set('#FEFE50', '#FFFEEA');
    colorMap.set('#01FB2B', '#F3FAEB');
    colorMap.set('#64B5F6', '#DEF5FF');
    colorMap.set('#D8A5EA', '#FDF4FF');
    colorMap.set('#748182', '#E7E7E7');
    colorMap.set('#B63028', '#FFDEDE');
    colorMap.set('#CB4A12', '#FFDEDE');
    colorMap.set('#EF9423', '#FFFEEA');
    colorMap.set('#2BC96A', '#F3FAEB');
    colorMap.set('#1C977A', '#F3FAEB');
    colorMap.set('#2C71AE', '#EEF0FD');
    colorMap.set('#8430A1', '#FDF4FF');
    colorMap.set('#6B4030', '#FFF0DF');
  }
  return colorMap;
};
const getColorStorageMap = (isBoldColors: boolean): Map<string, string> => {
  let colorMap = new Map<string, string>();
  if (isBoldColors) {
    colorMap.set('#F9F7F7', '#888888');
    colorMap.set('#FF6D00', '#E8731E');
    colorMap.set('#FEFE50', '#FBC30E');
    colorMap.set('#64B5F6', '#01B6E9');
    colorMap.set('#748182', '#454545');
    colorMap.set('#B63028', '#E90101');
    colorMap.set('#2BC96A', '#6DAD35');
    colorMap.set('#2C71AE', '#195893');
    colorMap.set('#8430A1', '#A335AD');
    colorMap.set('#6B4030', '#95520B');
  } else {
    colorMap.set('#F9F7F7', '#F6F6F6');
    colorMap.set('#FF6D00', '#FFEDE0');
    colorMap.set('#FEFE50', '#FFFEEA');
    colorMap.set('#64B5F6', '#DEF5FF');
    colorMap.set('#748182', '#E7E7E7');
    colorMap.set('#B63028', '#FFDEDE');
    colorMap.set('#2BC96A', '#F3FAEB');
    colorMap.set('#2C71AE', '#EEF0FD');
    colorMap.set('#8430A1', '#FDF4FF');
    colorMap.set('#6B4030', '#FFF0DF');
  }
  return colorMap;
};
const getLightBorderColor = (hexValue: string): string => {
  // Find the index of the hex value in taskOptionsLight
  const index = colors.taskOptionsLight.indexOf(hexValue);

  // If the hex value is found, return the corresponding value from taskOptionsLightBorder
  if (index !== -1) {
    return colors.taskOptionsLightBorder[index];
  } else {
    // Handle the case when the hex value is not found (e.g., return a default value or handle accordingly)
    console.error('Hex value not found in taskOptionsLight:', hexValue);
    return "none"; // You can return a default value or handle the case accordingly
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 7px;
  border-radius: ${borderRadius}px;
  /* preserve rounded corners in child elements */

  &:focus {
    outline: 1px solid ${colors.focusOutline};
    outline-offset: 3px;
  }
`;

type ContentProps = {
  isDragging: boolean;
  readOnly?: boolean;
  isbold?: boolean
};

const Content = styled.div<ContentProps>`
  @keyframes jiggle {
    40% {
      transform: rotate(1deg);
    }
    80% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  position: relative;
  display: flex;
  min-height: 40px;
  border-radius: ${borderRadius}px;
  transition: background-color 0.2s ease;
  background-color: ${props => props.color};
  outline: 1px solid ${props => props.isbold ? 'none' : getLightBorderColor(props.color || '#ffffff')};
  box-shadow: ${({ isDragging }) =>
    isDragging ? `3px 3px 2px ${colors.shadow}` : 'none'};
  animation: ${({ isDragging }) =>
    isDragging ? `jiggle 0.25s infinite` : 'none'};
  animation-timing-function: linear;
  user-select: none;
  cursor: ${props =>
    props.readOnly ? `default` : props.isDragging ? 'grabbing' : 'grab'};
`;

const Text = styled.div`
  display: block;
  padding: ${sizes.story.padding}px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
`;

const EditModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-radius: ${borderRadius}px;
  padding-top: 35px;
`;

const ColorList = styled.div`
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
  display: grid;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 12px;
  border-radius: 6px;
  column-gap: 10px;
  width: 194px;
  height: 82px;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-around;
  overflow: hidden;
  background-color: white;
`;

type ColorListItemProps = {
  colorOptions: string[];
  isbold: boolean;
};

const ColorListItem = styled.button<ColorListItemProps>`
  background: ${props => props.color};
  padding: 0px;
  color: 'red';
  width: 29px;
  max-width: 29px;
  height: 29px;
  border: 0px;
  outline: 1px solid ${props => props.isbold ? 'none' : getLightBorderColor(props.color || '#ffffff')};
  outline-offset: -1px;

  &:focus {
    outline: 2px solid ${props => changeLightness(props.color || '#ffffff', props.isbold ? 1.5 : 0.5)} !important;
    outline-offset: -2px;
  }
`;

const TrashStyled = styled.div`
  position: absolute;
  top: -5px;
  left: -1px;
  cursor: pointer;
`;

const SaveStyled = styled.div`
  position: absolute;
  right: 12px;
  top: 5px;
  cursor: pointer;
`

const CheckStyled = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: transparent;
`;

const LottieCheckStyled = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const LottieTrashStyled = styled.div`
  position: absolute;
  left: -1px;
  top: -5px;
`;

const DeadlineStyled = styled.div`
  padding-top: 8px;
  font-size: 10px;
  display: flex;
`;