import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactGA from 'react-ga';

import {
  ADD_STORY_LOCALLY,
  FOCUS_STORY_LOCALLY,
  useStoreContext,
} from '../store';
import { getStageStories } from '../store/computed';
import { Story } from '../typings/story';
import { mapStoriesArray } from '../utils/board';
import { createNewStory } from '../utils/story';
import { Props as StoryListProps } from './StoryList';
import StoryList from './StoryList';
import { PremiumModal } from '../components/PremiumModal';
import axios from 'axios';
import { isFreeAccount } from '../utils/users';
import { LogEvent } from '../utils/analytics';

type Props = Omit<
  StoryListProps,
  'stories' | 'onAddStoryClick' | ' onAccountClick'
>;

export const StoryListContainer: React.FC<Props> = ({
  stage,
  onStoryCountChange,
  ...props
}) => {
  const filteredStoriesRef = useRef<Story[]>();
  const storiesRef = useRef<Story[]>();
  const { state, dispatch } = useStoreContext();
  const [goPremiumVisible, setGoPremiumVisible] = useState(false);

  const stories = useMemo(
    () =>
      getStageStories(
        state.stories,
        state.storiesIndex,
        stage,
        true,
        props.isCompletedCards,
        props.selectedPeriodCompletedCards,
      ),
    [
      stage,
      state.stories,
      state.storiesIndex,
      props.isCompletedCards,
      props.selectedPeriodCompletedCards,
    ],
  );
  const filteredStories = useMemo(() => {
    const oldStoriesMap = mapStoriesArray(storiesRef.current);
    const oldFilteredStoriesMap = mapStoriesArray(filteredStoriesRef.current);
    const newStories: Story[] = [];

    for (let i = 0; i < stories.length; i++) {
      const story = stories[i];
      if (oldStoriesMap[story.id] && oldFilteredStoriesMap[story.id]) {
        newStories.push(story);
      } else if (!oldFilteredStoriesMap[story.id]) {
        if (oldStoriesMap[story.id]) {
          continue;
        } else {
          newStories.push(story);
        }
      }
    }

    return newStories;
  }, [stories]);

  useEffect(() => {
    storiesRef.current = stories; // store previous stories
  }, [stories]);

  useEffect(() => {
    if (
      JSON.stringify(filteredStoriesRef.current) ==
      JSON.stringify(filteredStories)
    ) {
      return;
    }

    filteredStoriesRef.current = filteredStories; // store previous filtered stories
    if (onStoryCountChange) {
      onStoryCountChange(stories.length);
    }
  }, [filteredStories, onStoryCountChange]);

  const hideGoPremium = () => {
    setGoPremiumVisible(false);
  };

  const handleAddStoryClick = useCallback(() => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    let stories1: any = localStorage.getItem('stories');
    const story1 = JSON.parse(stories1);
    if (isFreeAccount() && story1 >= 20) {
      setGoPremiumVisible(true);
      return;
    }

    // console.log( accountInfo?.['story'] , "accountInfo")
    accountInfo['story'] = parseInt(accountInfo['story']) + 1;
    // console.log(accountInfo , "accountInfo1")
    localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
    const board = state.boards[stage.boardId];
    let lastStory = board.lastStoryId ? state.stories[board.lastStoryId] : undefined;
    const newStory = createNewStory(stage.id, {
      priority: stories.length,
      color: lastStory?.color || undefined,
    });
    dispatch({
      type: ADD_STORY_LOCALLY,
      payload: newStory,
    });
    dispatch({
      type: FOCUS_STORY_LOCALLY,
      payload: newStory,
    });
    ReactGA.event({
      category: 'task',
      action: 'taskCreated',
    });

    LogEvent('addTaskClicked', { index: stories.length, button: 'bottom' });
  }, [stage.id, stories, stories.length, dispatch]);

  if (!stage) {
    return null;
  }

  return (
    <div>
      <StoryList
        {...props}
        accountId=""
        stage={stage}
        stories={filteredStories}
        onAddStoryClick={handleAddStoryClick}
      />
      {goPremiumVisible && <PremiumModal hidePremiumModal={hideGoPremium} />}
    </div>
  );
};
