// @flow
import React, { Component, KeyboardEvent, MouseEvent, RefObject } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { StoryListContainer } from './StoryList.container';
import { Input } from '../common/Input';
import { colors, borderRadius, sizes } from '../theme';
import { Stage } from '../typings/stage';
import { Board } from '../typings/board';
import Icon from '../components/Icon';
import { Dropdown, MenuProps } from 'antd';
import { PeriodCompletedCards } from './ViewModels';
import { TFunction } from 'i18next';

export type Props = {
  accountId: string;
  isFocus?: boolean;
  index: number;
  innerRef?: RefObject<Draggable>;
  readOnly?: boolean;
  stage: Stage;
  isBoldColors: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  allActivesStages: Stage[];
  allActivesBoards: Board[];
  handleMoveCard?: (stage: Stage) => void;
  onAddStoryClick: () => void;
  onStageBlur: () => void;
  onStageDelete: (stage: Stage) => void;
  onStageTitleClick: (stage: Stage) => void;
  onStageUpdate: (stage: Stage) => void;
  t: TFunction<"translation", undefined, "translation">;
};

type State = {
  stage: Stage;
  storyCount: number;
  menuOpen: boolean;
};

export class StageDetails extends Component<Props, State> {
  headerWrapperRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      stage: props.stage,
      storyCount: 0,
      menuOpen: false,
    };
    this.headerWrapperRef = React.createRef();
  }

  static getDerivedStateFromProps(
    props: Props,
    state: State,
  ): Partial<State> | null {
    if (
      props.stage.updatedAt?.toISOString() !==
      state.stage.updatedAt?.toISOString()
    ) {
      return {
        stage: props.stage,
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside as any);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside as any);
  }

  saveStage = () => {
    const { onStageBlur, onStageUpdate } = this.props;
    const { stage } = this.state;
    const timestamp = new Date();
    onStageUpdate({
      ...stage,
      createdAt: stage.createdAt || timestamp,
      updatedAt: stage.updatedAt || timestamp,
    });
    onStageBlur();
  };

  /**
   * End editing and save the stage when tapping outside the stage
   */
  handleClickOutside = (event: MouseEvent) => {
    if (!this.headerWrapperRef) {
      return;
    }
    const node = this.headerWrapperRef.current;
    const { isFocus } = this.props;

    if (isFocus && node && !node.contains(event.target as Node)) {
      this.saveStage();
    }
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const {
      isFocus,
      onStageBlur,
      onStageDelete,
      onStageTitleClick,
    } = this.props;
    const { stage } = this.state;
    //Enter should toggle editmode
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isFocus) {
        this.saveStage();
      } else {
        onStageTitleClick(stage);
      }
    }
    //Delete (fn+backspace)
    else if (!isFocus && event.key === 'Delete') {
      onStageDelete(stage);
    }
    // Escape should cancel editing
    else if (isFocus && event.key === 'Escape') {
      //cancel editing of new stage by deleting it
      if (stage.createdAt === undefined) {
        onStageDelete(stage);
      }
      //cancel editing of existing stage by restoring the original title and leave edit mode
      else {
        this.setState({
          stage: this.props.stage,
        });
        onStageBlur();
      }
    }
  };

  handleTitleEdit = () => {
    const { readOnly, stage, onStageTitleClick } = this.props;
    if (readOnly) {
      return;
    }
    onStageTitleClick(stage);
  };

  handleTitleChange = (value: string) => {
    const { stage } = this.state;
    this.setState({
      stage: {
        ...stage,
        title: value,
      },
    });
  };

  handleStoryCountChange = (value: number) => {
    this.setState({
      storyCount: value,
    });
  };

  handleAddStoryClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onAddStoryClick();
  };

  handleDelete = () => {
    const { onStageDelete, t } = this.props;
    const { stage } = this.state;
    const message = t('Board.DeleteListMessage').replace('{0}', stage.title);
    if (window.confirm(message)) {
      onStageDelete(stage);
    }
  };

  renderDynamicHeaderContent = (
    stage: Stage,
    storyCount: number,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    readOnly: boolean,
  ) => {
    const items: MenuProps['items'] = [
      {
        key: 'edit',
        onClick: this.handleTitleEdit,
        title: 'Edit column title',
        label: <Icon style={{ cursor: 'pointer' }} name="pencil" />,
      },
      {
        key: 'delete',
        onClick: this.handleDelete,
        title: 'Delete column title',
        label: <Icon style={{ cursor: 'pointer' }} name="trash" />,
      },
    ];

    const { isFocus } = this.props;
    let content = null;
    if (isFocus && !readOnly) {
      content = (
        <EditModeWrapper {...provided.dragHandleProps}>
          <TextArea
            autoFocus
            name=""
            target="stage"
            value={stage.title}
            onChange={this.handleTitleChange}
          />
        </EditModeWrapper>
      );
    } else {
      content = (
        <Title {...provided.dragHandleProps}>
          <InnerTitle readOnly={readOnly}>{stage.title}</InnerTitle>
          <StoryCount>{storyCount}</StoryCount>
          {!readOnly && (
            <StageHeaderActions>
              <Icon
                fontSize={18}
                onClick={this.handleAddStoryClick}
                name="plus"
              />
              <Dropdown
                open={this.state.menuOpen}
                onOpenChange={open => this.setState({ menuOpen: open })}
                menu={{ items }}
                placement="bottomRight"
                trigger={['click']}>
                <Icon fontSize={18} name="dots" />
              </Dropdown>
            </StageHeaderActions>
          )}
        </Title>
      );
    }
    return content;
  };

  render() {
    const { accountId, index, isFocus, readOnly, isBoldColors, isCompletedCards, selectedPeriodCompletedCards } = this.props;
    const { stage, storyCount } = this.state;

    return (
      <Draggable
        draggableId={stage.id}
        index={index}
        isDragDisabled={readOnly}
        ref={this.props.innerRef}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (

          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <div className="main_header" ref={this.headerWrapperRef}>
              <Header
                isDragging={snapshot.isDragging}
                isEditing={!!isFocus}
                onKeyDown={this.handleKeyDown}>
                {this.renderDynamicHeaderContent(
                  stage,
                  storyCount,
                  provided,
                  snapshot,
                  !!readOnly,
                )}
              </Header>
            </div>
            <StoryListContainer
              allActivesStages={this.props.allActivesStages}
              allActivesBoards={this.props.allActivesBoards}
              accountId={accountId}
              isDropDisabled={stage.createdAt === undefined}
              readOnly={readOnly}
              isBoldColors={isBoldColors}
              onStoryCountChange={value => this.handleStoryCountChange(value)}
              stage={stage}
              isCompletedCards={isCompletedCards}
              selectedPeriodCompletedCards={selectedPeriodCompletedCards}
            />
          </Container>

        )}
      </Draggable>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${sizes.stage.width}px;
  margin-left: ${sizes.stage.margin}px;
  margin-right: 10px;
`;

type HeaderProps = {
  isEditing: boolean;
  isDragging: boolean;
};

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  background-color: ${props =>
    props.isEditing
      ? colors.stage.editModeBackgroundColor
      : props.isDragging
        ? colors.stage.hover
        : colors.stage.backgroundColor};
  transition: background-color 0.3s ease;
  height: 50px;
  &:hover {
    background-color: ${props =>
    props.isEditing
      ? colors.stage.editModeBackgroundColor
      : colors.stage.hover};
  }
`;

const StoryCount = styled.div`
  background-color: #f6f6f6;
  margin-right: 5px;
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
`;

type TitleProps = {
  readOnly: boolean;
};

const Title = styled.h4`
  width: calc(100% - 30px);
  align-self: center;
  padding-left: ${sizes.stage.titlePaddingLeft}px;
  flex-grow: 1;
  color: ${colors.black};
  user-select: none;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  &:focus {
    outline: 1px solid ${colors.focusOutline};
    outline-offset: 2px;
  }
`;

const InnerTitle = styled.h4<TitleProps>`
  cursor: ${props => (props.readOnly ? `default` : `grab`)};
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const StageHeaderActions = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 15px;
`;

const EditDeleteButton = styled.a`
  cursor: pointer;
  width: 24px;
  max-width: fit-content;

  &:hover svg {
    color: ${colors.task.blue} !important;
  }
`;

const EditModeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
`;

//todo pri2: replicating Title styling isn't DRY!
const TextArea = styled(Input)`
  background: transparent;
  color: ${colors.black};
  border: 0px;
  flex-grow: 1;
  font-family: sans-serif;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  outline: none;
  padding-bottom: 0;
  padding-left: ${sizes.stage.titlePaddingLeft}px;
  padding-top: 0;
  width: unset;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;
