import React, { useEffect, useMemo } from 'react';
import service from '../api/Service';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faXmark,
  faArrowLeft,
  faArrowRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons';


import { GoPremiumQuote } from '../sidebar/SidebarViewModel';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
import { FastSpringContext } from '../store/FastSpringContext';
import { AccountInfo, UserData } from '../account/AccountPanel';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '..';

interface PremiumModalProps {
  hidePremiumModal: () => void;
  style?: React.CSSProperties;
}

const goPremiumQuotes: GoPremiumQuote[] = [
  new GoPremiumQuote(
    'GoPremiumQuote.Text1',
    'Helen C.',
    '',
    '',
    5,
  ),
  new GoPremiumQuote(
    `GoPremiumQuote.Text2`,
    'Tammy K.',
    '',
    '',
    5,
  ),
  new GoPremiumQuote(
    `GoPremiumQuote.Text3`,
    'Bob H',
    '',
    '',
    5,
  ),
  new GoPremiumQuote(
    `GoPremiumQuote.Text4`,
    'Giselle S.',
    '',
    '',
    5,
  ),
  new GoPremiumQuote(
    `GoPremiumQuote.Text5`,
    'Dr. H Kimpson',
    '',
    '',
    5,
  ),
];

export const PremiumModal = (props: PremiumModalProps) => {
  const { hidePremiumModal } = props;
  const { t } = useTranslation()
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const { plans } = React.useContext(FastSpringContext);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [userPlan, setUserPlan] = useState([]);

  const accountInfoRaw: string | null = localStorage.getItem('AccountInfo');
  const accountInfo: AccountInfo | undefined = accountInfoRaw
    ? JSON.parse(accountInfoRaw)
    : undefined;
  // const [ avatarUrl, setAvatarUrl ] = useState('')

  useEffect(() => {
    (async () => {
      const userData = await fetchUserData().catch(() => {
        console.log('Could not load user data');
      });

      if (!userData) {
        return;
      }

      const userFastSpringPlans = await service.getFastSpringUserPlans(accountInfo?.Id ?? '');
      setEmail(userData.email);
      const fastSpringPlans = plans.filter((plan: any) => userFastSpringPlans?.plansIds.includes(plan.pid));
      setUserPlan(fastSpringPlans);
    })();
  }, [accountInfo?.Id]);

  useEffect(() => {
    (async () => {
      const userFastSpringPlans = await service.getFastSpringUserPlans(accountInfo?.Id ?? '');
      const fastSpringPlans = plans.filter((plan: any) => userFastSpringPlans?.plansIds.includes(plan.pid));
      setUserPlan(fastSpringPlans);
      setLoading(false);
    })();
  }, [plans])

  const fetchUserData = async (): Promise<UserData> => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          resolve({
            uid: user?.uid ?? '',
            email: user?.email ?? '',
            providerId: user?.providerData[0]?.providerId ?? ''
          });
        } else {
          console.error('Error retrieving user data');
          reject();
        }
        // unsubscribe from the listener
        unsubscribe();
      });
    });
  };

  const goPremiumLeftArrow = () => {
    const lastIndex = goPremiumQuotes.length - 1;

    // Calculate the next index, wrapping around if needed
    const nextIndex =
      currentQuoteIndex === 0 ? lastIndex : currentQuoteIndex - 1;

    setCurrentQuoteIndex(nextIndex);
  };

  const goPremiumRightArrow = () => {
    const lastIndex = goPremiumQuotes.length - 1;

    // Calculate the next index, wrapping around if needed
    const nextIndex =
      currentQuoteIndex === lastIndex ? 0 : currentQuoteIndex + 1;

    setCurrentQuoteIndex(nextIndex);
  };

  const reportDesktopCheckout = (distributor: string) => {
    // Check if "Checkout" event has been sent
    let checkout_key = "td_kanbana_event_checkout";
    let checkout_check = localStorage.getItem(checkout_key);
    if (!checkout_check) {
      // If we have not sent the event, send it now
      fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Checkout");
      localStorage.setItem(checkout_key, "sent");
    }
  };

  const buyProduct = (selectedItem: any) => {
    if (!isNil(selectedItem)) {

      var d = localStorage.getItem("kanbana_distributor") ?? "Unknown";
      var dDate = localStorage.getItem("kanbana_distributor_date") ?? "Unknown";
      if (isDesktopApp()) {
        // Send Premium event
        reportDesktopCheckout(d);
      }

      // @ts-ignore
      window.fastspring.builder.push({
        products: [
          {
            // @ts-ignore
            path: selectedItem.path,
            quantity: 1,
          }
        ],
        paymentContact: {
          email
        },
        checkout: true,
        tags: {
          distributor: d,
          date: dDate
        }
      });
    }
  };

  return (
    <div className="premium-modal" onClick={hidePremiumModal}>
      <div
        className="premium-modal-content"
        onClick={evt => {
          evt.stopPropagation();
        }}
        style={{
          height: '776px',
          width: '1172px',
          display: 'flex',
        }}>
        <div className="left">
          <div className="inner-container">
            <div className="inner-content">
              <div className="heading-check-items">
                <div className="heading">
                  {t('Premium.UpgradeTo')}
                  <wbr />
                  {t('Premium.KanbanaPremium')}
                </div>
                <p>
                  {t('Premium.FreeUsers20Cards')} {t('Premium.CupOfCoffee')}
                </p>
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="#01b6e9"
                      style={{ fontSize: '28px' }}
                    />
                    {t('Premium.UnlimitedCards')}
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="#01b6e9"
                      style={{ fontSize: '28px' }}
                    />
                    {t('Premium.UnlimitedBoards')}
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="#01b6e9"
                      style={{ fontSize: '28px' }}
                    />
                    {t('Premium.NoAds')}
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="#01b6e9"
                      style={{ fontSize: '28px' }}
                    />
                    {t('Premium.SupportKanbana')} 👍
                  </li>
                </ul>
                <p>{t('Premium.FreeTrailText')}</p>
              </div>

              <div className="actions">
                {
                  !isNil(userPlan) && !isEmpty(userPlan) && userPlan.map((product: any) => {
                    const isYearly = product.display.includes("Yearly");
                    return (
                      <button
                        key={isYearly ? "Yearly" : "Monthly"}
                        className={isYearly ? "Yearly" : "Monthly"}
                        onClick={() => buyProduct(product)}
                      >
                        {product?.future?.total ?? product.total} / {isYearly ? t('GoPremiumQuote.Year') : t('GoPremiumQuote.Month')}
                        {isYearly && <span className="badge">{t('GoPremiumQuote.BestValue')}</span>}
                      </button>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <button className="close-btn" onClick={hidePremiumModal}>
            <FontAwesomeIcon
              icon={faXmark}
              color="#fff"
              style={{ fontSize: '24px' }}
            />
          </button>
          <div className="quote-container">
            <div className="quote">
              "{t(goPremiumQuotes[currentQuoteIndex].quote)}"
            </div>
            <div className="attribution-container">
              <div className="attribution-top">
                <div className="name">
                  {goPremiumQuotes[currentQuoteIndex].name}
                </div>
              </div>
              <div className="attribution-bottom">
                <div className="where-when">
                  <p>{goPremiumQuotes[currentQuoteIndex].place}</p>
                  <p>{goPremiumQuotes[currentQuoteIndex].date}</p>
                </div>
                <div className="arrows">
                  <button onClick={goPremiumLeftArrow}>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ fontSize: '24px' }}
                      className="fa-icon"
                    />
                  </button>
                  <button onClick={goPremiumRightArrow}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ fontSize: '24px' }}
                      className="fa-icon"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
