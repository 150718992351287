import React, { CSSProperties } from "react"

export interface ProgressBarProps {
  val: number 
  maxVal: number
  width?: CSSProperties["width"]
  height?: CSSProperties["height"]
  color?: CSSProperties["color"]
  style?: CSSProperties
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { val, maxVal, color, height, width, style } = props

    const percentFilled = 100*Math.min((val/maxVal), 1)

    return (
      <div className='kb-progress-bar' style={{height, width, ...style}}>
        <div className='filled-bar' style={{height, width: percentFilled + '%', color}}></div>
        <div className='empty-bar' style={{height, width: '100%'}}></div>
      </div>
    )
}