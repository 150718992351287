import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { IconStyled } from './index.styled';
import IconAntd from '@ant-design/icons';
import svgs, { TypeIcons } from './svg';

type Props = {
  name: TypeIcons;
  backgroundCircle?: 'dark' | 'light';
  fontSize?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
  className?: string;
  id?: string;
};

const Icon = ({
  name,
  backgroundCircle,
  fontSize,
  onClick,
  style,
  id,
}: Props) => {
  const [svg, setSvg] = useState<any>();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) onClick(event);
    },
    [onClick],
  );

  useEffect(() => {
    if (name) setSvg(svgs[name]);
  }, [name]);

  return (
    <div onClick={handleClick}>
      <IconStyled
        id={id}
        style={{
          lineHeight: (fontSize ?? 24) + 'px',
          fontSize: fontSize ?? 18,
          cursor: onClick ? 'pointer' : 'initial',
          ...style,
        }}
        className={`${
          backgroundCircle ? `bk-circle ${backgroundCircle}` : ''
        } icon-${name}`}>
        {svg && <IconAntd component={svg} />}
      </IconStyled>
    </div>
  );
};

export default Icon;
