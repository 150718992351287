export class BoardMenuItem {
  title: string;
  boardId: string;
  isSelected: boolean;

  constructor(title: string, boardId: string, isSelected: boolean) {
    this.title = title;
    this.boardId = boardId;
    this.isSelected = isSelected;
  }
}
