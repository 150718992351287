import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import welcome from '../resources/images/welcome.jpg';
import tmedia1 from '../resources/images/tutorial_001.webp';
import tmedia2 from '../resources/images/tutorial_002.webp';
import tmedia3 from '../resources/images/tutorial_003.webp';
import tmedia4 from '../resources/images/tutorial_004.webp';
import { t } from 'i18next';

interface GetStartedModalProps {
  hide: () => void;
}

interface StepProperties {
  handleIncrementStep: () => void;
  handleDecrementStep: () => void;
  handleClose: () => void;
  dotNav: JSX.Element;
}

interface MediaStepProperties extends StepProperties {
  mediaType?: 'image' | 'video';
  mediaPath?: string;
  title: string;
  text: string[];
  showSkipButton?: boolean;
}

interface TutorialStepProperties extends StepProperties {
  mediaPath?: string;
  title: string;
  text: string[];
  tasks?: string[];
  currentStep: number;
  totalSteps: number;
  skipText?: string;
  hideSkip?: boolean;
}

const MediaStep = (props: MediaStepProperties) => {
  const {
    handleIncrementStep,
    handleDecrementStep,
    handleClose,
    mediaType,
    mediaPath,
    title,
    text,
    showSkipButton,
    dotNav,
  } = props;

  return (
    <div className="media-step">
      <div className="media-wrapper">
        {mediaType === 'video' ? (
          <video src={mediaPath} width="352" height="192" />
        ) : (
          <img src={mediaPath} alt="tutorial image" width="352" height="192" />
        )}
      </div>
      <div className="text-wrapper">
        <div className="get-started-title-sm">{title}</div>
        <div
          className="get-started-text-sm"
          dangerouslySetInnerHTML={{ __html: text.join('<br /><br />') }}
        />
      </div>
      {dotNav}
      <hr />
      <div className="button-container">
        {showSkipButton && (
          <button className="btn-light" onClick={handleClose}>
            {t('GettingStarted.SkipTutorial')}
          </button>
        )}
        <button className="btn-blue" onClick={handleIncrementStep}>
          {t('GettingStarted.Continue')}
        </button>
      </div>
    </div>
  );
};

const TutorialStep = (props: TutorialStepProperties) => {
  const {
    handleIncrementStep,
    handleDecrementStep,
    handleClose,
    title,
    text,
    tasks,
    currentStep,
    totalSteps,
    mediaPath,
    dotNav,
  } = props;

  return (
    <div className="tutorial-wrapper">
      <div className="tutorial-content">
        <div className="tutorial-nav">
          <div>
            <b>
              {currentStep + 1} {t('GettingStarted.Of')} {totalSteps}
            </b>
          </div>
          {currentStep > 0 &&
            (<div
              onClick={handleDecrementStep}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ fontSize: '14px', marginRight: '5px' }}
                className="fa-icon"
              />
              {t('GettingStarted.Back')}
            </div>)}
        </div>

        <hr />

        <div className="text-wrapper">
          <div>
            <div className="tutorial-title">{title}</div>
            <div
              className="tutorial-text"
              dangerouslySetInnerHTML={{ __html: text.join('<br /><br />') }}
            />
            {tasks && (
              <div className="tutorial-text" style={{ marginTop: '16px' }}>
                {tasks?.map(task => (
                  <div
                    key={task}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      color="#01b6e9"
                      style={{ fontSize: '28px', margin: '16px' }}
                    />
                    {task}
                  </div>
                ))}
              </div>
            )}
          </div>

          {dotNav}
        </div>

        <hr />

        <div className="button-container">
          {!props.hideSkip && <button className="btn-light" onClick={handleClose}>
            {t('GettingStarted.Skip')}
          </button>}
          <button className="btn-blue" onClick={handleIncrementStep}>
            {t('GettingStarted.Continue')}
          </button>
        </div>
      </div>

      <div>
        <img style={{ objectFit: 'cover' }} src={mediaPath} alt="getting started" width="100%" height="100%" />
      </div>
    </div>
  );
};

export const GetStartedModal = (props: GetStartedModalProps) => {
  const { t } = useTranslation()
  const { hide } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [whiteOut, setWhiteOut] = useState(false);
  const totalSteps = 6;

  const DotNav = (props: { handleNav: (idx: number) => void }) => (
    <div className="dot-nav-container">
      <div className="dot-nav">
        {Array(totalSteps)
          .fill(null)
          .map((_, idx) => (
            <div
              key={idx}
              style={{
                backgroundColor: idx === currentStep ? '#01B6E9' : '#F2F4F7',
                transform: idx === currentStep ? 'scale(1.25)' : 'scale(1)',
              }}
              onClick={() => {
                props.handleNav(idx);
              }}
            />
          ))}
      </div>
    </div>
  );

  const handleNav = async (idx: number) => {
    const currentWidth = Steps[currentStep]?.width;
    const nextWidth = Steps[idx]?.width;
    const currentHeight = Steps[currentStep]?.height;
    const nextHeight = Steps[idx]?.height;

    if (currentWidth === nextWidth && currentHeight === nextHeight) {
      setCurrentStep(idx);
      return;
    }

    setWhiteOut(true);

    setTimeout(() => {
      setCurrentStep(idx);
      setTimeout(() => {
        setWhiteOut(false);
      }, 300);
    }, 300);
  };

  const stepProps = {
    handleIncrementStep: () => {
      handleNav(currentStep + 1);
    },
    handleDecrementStep: () => {
      handleNav(currentStep - 1);
    },
    handleClose: () => {
      hide();
    },
    dotNav: DotNav({ handleNav }),
  };

  const Steps = [
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step1Title')}
          text={[t('GettingStarted.Step1Text')]}
          mediaPath={welcome}
          currentStep={currentStep}
          totalSteps={totalSteps}
          skipText='GettingStarted.SkipTutorial'
        />
      ),
    },
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step2Title')}
          text={[
            t('GettingStarted.Step2Text'),
          ]}
          currentStep={currentStep}
          totalSteps={totalSteps}
          mediaPath={tmedia1}
        />
      ),
    },
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step3Title')}
          text={[
            t('GettingStarted.Step3Text'),
          ]}
          tasks={[
            t('GettingStarted.Step3Task1'),
            t('GettingStarted.Step3Task2'),
          ]}
          currentStep={currentStep}
          totalSteps={totalSteps}
          mediaPath={tmedia2}
        />
      ),
    },
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step4Title')}
          text={[]}
          tasks={[
            t('GettingStarted.Step4Task1'),
            t('GettingStarted.Step4Task2'),
            t('GettingStarted.Step4Task3'),
          ]}
          currentStep={currentStep}
          totalSteps={totalSteps}
          mediaPath={tmedia3}
        />
      ),
    },
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step5Title')}
          text={[t('GettingStarted.Step5Text')]}
          tasks={[
            t('GettingStarted.Step5Task1'),
            t('GettingStarted.Step5Task2'),
            t('GettingStarted.Step5Task3'),
          ]}
          currentStep={currentStep}
          totalSteps={totalSteps}
          mediaPath={tmedia4}
        />
      ),
    },
    {
      width: '1286px',
      height: '563px',
      content: (
        <TutorialStep
          {...stepProps}
          title={t('GettingStarted.Step6Title')}
          text={[
            t('GettingStarted.Step6Text1'),
            t('GettingStarted.Step6Text2'),
          ]}
          mediaPath={welcome}
          currentStep={currentStep}
          totalSteps={totalSteps}
          hideSkip
        />
      ),
    },
  ];

  return (
    <div className="premium-modal" onClick={hide}>
      <div
        className="premium-modal-content get-started"
        onClick={evt => {
          evt.stopPropagation();
        }}
        style={{
          height: Steps[currentStep]?.height,
          width: Steps[currentStep]?.width,
        }}>
        <div style={{ opacity: whiteOut ? 0 : 1 }}>
          {Steps[currentStep]?.content || hide()}
        </div>
      </div>
    </div>
  );
};
