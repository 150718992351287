import { useCallback } from 'react';

import * as AsyncService from '../api/AsyncService';
import {
  GET_BOARD_SUCCESS,
  LOAD_STAGES_SUCCESS,
  LOAD_STORIES_SUCCESS,
  useStoreContext,
} from '../store';

export const useGetSharedBoard = () => {
  const { dispatch } = useStoreContext();

  const getSharedBoard = useCallback(
    async (token: string) => {
      try {
        const { stages, stories, ...board } = await AsyncService.getSharedBoard(
          token,
        );
        dispatch({
          type: GET_BOARD_SUCCESS,
          payload: board,
        });
        dispatch({
          type: LOAD_STAGES_SUCCESS,
          payload: stages,
        });
        dispatch({ type: LOAD_STORIES_SUCCESS, payload: stories });
        return board;
      } catch {}
    },
    [dispatch],
  );

  return getSharedBoard;
};
