import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { DraggableLocation } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';
import * as uuid from 'uuid';
import { PeriodCompletedCards } from './ViewModels';

import {
  ADD_STAGE_LOCALLY,
  FOCUS_STAGE_LOCALLY,
  MOVE_CARD_DIFFERENT_COLUMN,
  MOVE_CARD_SAME_COLUMN,
  UPDATE_STAGES_LOCALLY,
  useStoreContext,
} from '../store';
import { getBoardStages, getStageStories } from '../store/computed';
import { Stage } from '../typings/stage';
import { arrayMove } from '../utils/array';
import { updateStagePriorities } from '../utils/board';
import BoardDetails from './BoardDetails';
import { isNil } from 'lodash';

type Props = {
  accountId?: string;
  boardId?: string;
  isBoldColors: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
};

export const BoardDetailsContainer: React.FC<Props> = memo(({ boardId, isBoldColors, isCompletedCards, selectedPeriodCompletedCards }) => {
  const { state, dispatch } = useStoreContext();
  const board = useMemo(() => {
    if (!boardId) {
      return undefined;
    }
    return state.boards[boardId];
  }, [boardId]);

  const stages = useMemo(
    () => getBoardStages(state.stages, state.stagesIndex, boardId),
    [boardId, state.stages, state.stagesIndex],
  );

  const stories = useMemo(() => state.stories, [state.stories, state.internalUpdateDate]);

  const activesStages = useMemo(() => {
    return Object.values(state.stages).filter(x => isNil(x.deletedAt));
  }, [state.stages]);

  const activesBoards = useMemo(() => {
    return Object.values(state.boards).filter(x => isNil(x.deletedAt));
  }, [state.stages]);

  const handleAddStageClick = useCallback(() => {
    if (!boardId) {
      return;
    }
    const id = uuid.v1();
    const newStage: Stage = {
      id,
      boardId,
      isLast: true,
      priority: stages.length,
      storyLimit: 0,
      title: '',
    };

    dispatch({
      type: ADD_STAGE_LOCALLY,
      payload: newStage,
    });
    dispatch({
      type: FOCUS_STAGE_LOCALLY,
      payload: newStage,
    });
    ReactGA.event({
      category: 'stage',
      action: 'stageCreated',
    });
  }, [boardId, stages]);

  // TODO: add GA event tracking
  const handleStageDragEnd = useCallback(
    (destination: DraggableLocation, source: DraggableLocation) => {
      if (source.index !== destination.index) {
        const newStages = arrayMove(stages, source.index, destination.index);

        const updatedStages = updateStagePriorities(newStages);
        dispatch({
          type: UPDATE_STAGES_LOCALLY,
          payload: {
            boardId,
            stages: updatedStages,
          },
        });
        ReactGA.event({
          category: 'stage',
          action: 'stagePriorityChanged',
        });
      }
    },
    [boardId, stages],
  );

  // TODO: add GA event tracking
  const handleStoryDragEnd = useCallback(
    (destination: DraggableLocation, source: DraggableLocation) => {
      const fromStage = stages.find(stage => stage.id === source.droppableId);
      const toStage = stages.find(
        stage => stage.id === destination.droppableId,
      );

      if (!fromStage || !toStage) {
        return;
      }
      const fromStageStories = getStageStories(
        stories,
        state.storiesIndex,
        fromStage,
      );

      const story = {
        ...fromStageStories[source.index],
      };
      const fromIndex = source.index;
      const toIndex = destination.index;

      if (fromStage === toStage && fromIndex !== toIndex) {
        story.priority = toIndex;
        dispatch({
          type: MOVE_CARD_SAME_COLUMN,
          payload: {
            story,
            cards: fromStageStories,
            toIndex,
          }
        });
        ReactGA.event({
          category: 'task',
          action: 'taskPriorityChanged',
        });
      } else if (fromStage !== toStage) {
        const toStageStories = getStageStories(
          stories,
          state.storiesIndex,
          toStage,
        );

        story.stageId = toStage.id;
        story.priority = toIndex;

        dispatch({
          type: MOVE_CARD_DIFFERENT_COLUMN,
          payload: {
            story,
            cardsInitialList: fromStageStories,
            cardsEndList: toStageStories,
            toIndex,
          }
        });

        ReactGA.event({
          category: 'task',
          action: 'taskStageChanged',
        });
      }
    },
    [stages, state.storiesIndex, stories],
  );

  if (!board) {
    return null;
  }

  return (
    <BoardDetails
      allActivesStages={activesStages}
      allActivesBoards={activesBoards}
      board={board}
      stages={stages}
      isBoldColors={isBoldColors}
      onAddStageClick={handleAddStageClick}
      onStageDragEnd={handleStageDragEnd}
      onStoryDragEnd={handleStoryDragEnd}
      isCompletedCards={isCompletedCards}
      selectedPeriodCompletedCards={selectedPeriodCompletedCards}
    />
  );
});
