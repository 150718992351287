import React from 'react';
import fontawesome from '@fortawesome/fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { colors } from '../theme';

const BusyIndicator = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default class Spinner extends React.Component {
  constructor(props: any) {
    super(props);
    fontawesome.library.add(faSpinner as any);
  }

  render() {
    return (
      <BusyIndicator>
        <FontAwesomeIcon
          icon="spinner"
          size="3x"
          className="fa-spin"
          color={colors.black}
        />
      </BusyIndicator>
    );
  }
}
