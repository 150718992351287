import dayjs from 'dayjs';

export function lastDays(date: Date, days: number): boolean {
  return dayjs().diff(dayjs(date), 'days') <= days;
}

export function lastMonths(date: Date, months: number): boolean {
  return dayjs().diff(dayjs(date), 'months') <= months;
}

export function lastYears(date: Date, years: number): boolean {
  return dayjs().diff(dayjs(date), 'years') <= years;
}