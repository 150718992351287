import { useCallback, useContext } from 'react';

import { useDatabase } from '../database/hooks';
import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { Story } from '../typings/story';
import { StoreContext } from './StoreProvider';
import { synchronize } from './operations';

export type Synchronize = (
  { accountId, trigger, pusher }: { accountId: string; trigger: string; pusher: number },
  updatedBoards?: Board[],
  updatedStages?: Stage[],
  updatedStories?: Story[],
) => Promise<{ boards: Board[]; stages: Stage[]; stories: Story[] }>;

export const useStoreContext = () => useContext(StoreContext);

export const useSynchronize = () => {
  const database = useDatabase();
  const { dispatch, state } = useStoreContext();

  return useCallback(
    async (
      config: { accountId: string; trigger: string; pusher?: number },
      updatedBoards?: Board[],
      updatedStages?: Stage[],
      updatedStories?: Story[],
    ) =>
      synchronize(
        database,
        dispatch,
        config,
        updatedBoards,
        updatedStages,
        updatedStories,
        state.lastSynchronizedAt,
      ),
    [database, state, dispatch],
  );
};
