import React, { FunctionComponent, ReactNode } from 'react';

interface BaseLayoutProps {
  children?: ReactNode;
}

export const FastSpringContext = React.createContext<any | null>(null);

const FastSpringProvider: FunctionComponent<BaseLayoutProps> = ({ children }) => {
  const [plans, setPlans] = React.useState<any[]>([]);

  const addPlans = (newPlans: any) => {
    const newItems = newPlans.reverse();
    setPlans(newItems);
  }

  return (
    <FastSpringContext.Provider value={{ plans, addPlans }}>
      {children}
    </FastSpringContext.Provider>
  );
};

export default FastSpringProvider;
