import React from 'react';
import { t } from 'i18next';
import { PhoneOutlined, GoogleOutlined } from '@ant-design/icons';
import { TbBrandFacebook } from "react-icons/tb";
import { FaApple } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";



export const getUserProviderIdTranslation = (providerId: string) => {
  try {
    if (providerId === 'password') return t("Account.ProviderIdEmail");
    if (providerId === 'phone') return t("Account.ProviderIdPhone");
    if (providerId === 'google.com') return t("Account.ProviderIdGoogle");
    if (providerId === 'facebook.com') return t("Account.ProviderIdFacebook");
    if (providerId === 'apple.com') return t("Account.ProviderIdApple");
    return providerId;
  } catch (err) {
    return 'Without identification';
  }
};

export const getAuthenticationIcon = (providerId: string) => {
  if (providerId === 'password') return <MdOutlineEmail size={20} />;
  if (providerId === 'phone') return <PhoneOutlined size={20} />;
  if (providerId === 'google.com') return <GoogleOutlined size={20} />;
  if (providerId === 'facebook.com') return <TbBrandFacebook size={20} />;
  if (providerId === 'apple.com') return <FaApple size={20} />;
  return <></>;
};

