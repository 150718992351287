import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { styled } from 'styled-components';

export const Flag = styled.img``;

export const FlexLaguage = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #3d3d3d;
  gap: 12px;
`;

export const DropdownLaguage = styled(Dropdown)`
  margin-left: 12px;
`;

export const DropdownBoardButton = styled(Button)`
  padding: 0 !important;
`;

export const FlexBoard = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  color: #3d3d3d;
  gap: 12px;
`;

export const BoardCount = styled.div`
  height: 22px;
  padding: 2px 8px 2px 8px;
  border-radius: 16px;
  background: #01b6e9;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TemplateTitle = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 6px;

  > button {
    position: fixed;
    top: 50%;
    left: 50%;
  }
`;

export const ArrowLeftOutlinedStyled = styled(ArrowLeftOutlined)`
  cursor: pointer;
`;

export const FlexCompletedCards = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #3d3d3d;
  gap: 12px;
`;

export const DropdownPeriod = styled(Dropdown)`
  margin-left: 12px;
`;