import React, { useEffect, useState } from 'react';
import { Badge, Button, MenuProps, Popover, Select } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { FlexCompletedCards } from '../sidebar/Sidebar.styled';
import { useTranslation } from 'react-i18next';
import { Story } from '../typings/story';
import { PeriodCompletedCards } from '../board/ViewModels';
import { lastMonths, lastDays, lastYears } from '../utils/date';
import { styled } from 'styled-components';
import { range } from 'lodash';

export interface CompletedCardsSelectorProps {
  selectedPeriod: PeriodCompletedCards;
  isCompletedCards: boolean;
  completedStories: Story[];
  handleToggleCompletedCards: () => void;
  selectPeriod: (period: PeriodCompletedCards) => void;
}

export const CompletedCardsSelector = ({ selectPeriod, handleToggleCompletedCards, isCompletedCards, selectedPeriod, completedStories }: CompletedCardsSelectorProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedPeriodTranslate, setSelectedPeriodTranslate] = useState<string>();
  const [selectedPeriodCount, setSelectedPeriodCount] = useState<number>(0);
  const [periodDate, setPeriodDate] = useState<number[]>(range(1, 31));

  useEffect(() => {
    switch (selectedPeriod.periodType) {
      case 'Days':
        setPeriodDate(range(1, 32));
        break;
      case 'Months':
        setPeriodDate(range(1, 13));
        break;
      case 'Years':
        setPeriodDate(range(1, 11));
        break;
    }
  }, [selectedPeriod.periodType])

  useEffect(() => {
    switch (selectedPeriod.periodType) {
      case 'Days':
        setSelectedPeriodTranslate(t('Board.LastDays', { count: selectedPeriod.period }).toString());
        setSelectedPeriodCount(completedStories.filter(story => !story.deletedAt && story.completedAt && lastDays(story.completedAt!, selectedPeriod.period)).length);
        break;
      case 'Months':
        setSelectedPeriodTranslate(t('Board.LastMonths', { count: selectedPeriod.period }).toString());
        setSelectedPeriodCount(completedStories.filter(story => !story.deletedAt && story.completedAt && lastMonths(story.completedAt!, selectedPeriod.period)).length);
        break;
      case 'Years':
        setSelectedPeriodTranslate(t('Board.LastYears', { count: selectedPeriod.period }).toString());
        setSelectedPeriodCount(completedStories.filter(story => !story.deletedAt && story.completedAt && lastYears(story.completedAt!, selectedPeriod.period)).length);
        break;
    }
  }, [selectedPeriod, t, completedStories])

  return (
    <CompletedCardsStyled className='toggle-switch-container'>
      {/* <Button style={{ display: 'flex', alignItems: 'center', gap: 8 }} size="large"> */}
      <div className="toggle-switch" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToggleCompletedCards();
      }}>
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          checked={isCompletedCards}
        />
        <span className="toggle-switch-slider"></span>
      </div>
      <label style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 10 }} className="toggle-switch-label">
        {t('Board.CompletedCards')}
      </label>
      {isCompletedCards && <Popover overlayStyle={{ width: 200 }} content={<SelectPeriodStyled>
        <div>Select Period</div>
        <Select value={selectedPeriod.period} onChange={(value) => selectPeriod({
          period: value,
          periodType: selectedPeriod.periodType
        })} options={periodDate.map(value => ({
          label: value,
          value: value
        }))} />
        <Select value={selectedPeriod.periodType} onChange={(value) => selectPeriod({
          period: 1,
          periodType: value
        })} options={[{
          label: t('Board.Days'),
          value: 'Days'
        }, {
          label: t('Board.Months'),
          value: 'Months'
        }, {
          label: t('Board.Years'),
          value: 'Years'
        }]} />
      </SelectPeriodStyled>} arrow={false} placement='bottom' trigger='click' open={open} onOpenChange={setOpen}>
        <ButtonStyled type='text'>
          <Badge showZero count={selectedPeriodCount} />
          {selectedPeriodTranslate}
          {open ? <UpOutlined /> : <DownOutlined />}
        </ButtonStyled>
      </Popover>}
    </CompletedCardsStyled>
  );
};

const CompletedCardsStyled = styled.div`
  height: 21px;
  margin-left: 10px;
  gap: 10px;
`;


const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SelectPeriodStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
`