import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Modal from '../Modal';
import { BoardMenuItem } from '../menu/MenuViewModel';
import {
  ModalCloseButton,
  ModalContainer as BaseModalContainer,
  TextButton,
} from '../styling/Components';
import { colors } from '../theme';
import { CopyBoardResponse } from '../typings/board';
import { Dictionary } from '../typings/utils';
import { BoardListItem } from './BoardListItem';
import { useTranslation } from 'react-i18next';

type Props = {
  addBoardModalVisible: boolean;
  boardMenuItems: BoardMenuItem[];
  onAddBoardClick: () => void;
  onAddBoardTemplateClick: () => void;
  onBoardCopied: (response: CopyBoardResponse) => void;
  onBoardDelete: (boardId: string) => void;
  onBoardDragEnd: (result: DropResult) => void;
  onBoardSelect: (item: BoardMenuItem) => void;
  onBoardUpdate: (item: BoardMenuItem, title: string) => void;
  onDismiss: () => void;
  IsKanbanaPremiumEnabled?: boolean;
  isModal: boolean;
  isMenuExpanded: boolean;
};

export const BoardList: React.FC<Props> = ({
  addBoardModalVisible,
  boardMenuItems,
  onAddBoardClick,
  onAddBoardTemplateClick,
  onBoardCopied,
  onBoardDelete,
  onBoardDragEnd,
  onBoardSelect,
  onBoardUpdate,
  onDismiss,
  isModal,
  isMenuExpanded,
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<Dictionary<boolean>>({});
  const [modalVisible, setModalVisible] = useState(false);

  const handleItemClick = useCallback(
    (item: BoardMenuItem) => {
      let action = localStorage.getItem('action');
      onBoardSelect(item);
      if (action) {
        localStorage.removeItem('action');
      } else {
        onDismiss();
      }
    },

    [onBoardSelect],
  );
  const handleDeleteClick = useCallback(
    (item: BoardMenuItem) => {
      const deleteConfirmed = window.confirm(
        t('Board.DeleteBoardMessage') ?? '',
      );
      if (deleteConfirmed) {
        onBoardDelete(item.boardId);
        if (item.isSelected) {
          const filteredBoardItems = boardMenuItems.filter(
            it => it.boardId !== item.boardId,
          );
          if (filteredBoardItems.length > 0) {
            onBoardSelect(filteredBoardItems[0]);
          } else {
            window.location.reload();
          }
        }
      }
    },
    [boardMenuItems, onBoardDelete, onBoardSelect],
  );
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (addBoardModalVisible || modalVisible || e.key !== 'Escape') {
        return;
      }
      const hasEditing = !!Object.values(editing).filter(item => !!item).length;
      if (hasEditing) {
        setEditing(s => {
          const keys = Object.keys(s);
          return keys.reduce(
            (o, k) => ({
              ...o,
              [k]: false,
            }),
            {},
          );
        });
      } else {
        onDismiss();
      }
    },
    [addBoardModalVisible, editing, modalVisible, onDismiss],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <ListContainer
        className={
          isModal
            ? isMenuExpanded
              ? 'listModalContainerExpanded'
              : 'listModalContainer'
            : ''
        }>
        <AddBoardButton onClick={onAddBoardTemplateClick}>
          {t('Modal.NewBoard')}
        </AddBoardButton>
        <br />
        <DragDropContext onDragEnd={onBoardDragEnd}>
          <Droppable direction="vertical" droppableId="board-list">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {boardMenuItems.map((item, index) => (
                  <BoardListItem
                    editing={!!editing[item.boardId]}
                    index={index}
                    item={item}
                    key={item.boardId}
                    onBoardCopied={onBoardCopied}
                    onDeleteClick={handleDeleteClick}
                    onClick={handleItemClick}
                    onTitleSubmit={onBoardUpdate}
                    setEditing={setEditing}
                    setModalVisible={setModalVisible}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListContainer>
    </>
  );
};

const AddBoardButton = styled(TextButton)`
  align-self: center;
  text-align: left;
  border-bottom: 1px solid #e3e6ec;
  color: #01b6e9;
  font-weight: 700;
  height: 44px;
  padding: 0 16px;
  width: 100%;
`;

const ListContainer = styled.div`
  overflow-x: hidden;
  max-height: 600px;
  overflow-y: auto;
  width: 280px;
  min-width: 100%;
  height: 100%;
`;

const CloseButton = styled(ModalCloseButton)`
  align-self: center;
`;

const ModalListContainer = styled.div`
  height: 80vh;
  max-height: 600px;
  overflow-y: auto;
  width: 100%;
`;

const ModalContainer = styled(BaseModalContainer)`
  max-width: 600px;
  padding: 0;
  width: 60vw;
`;

const ModalContent = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  width: 100%;
`;

const ModalHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.border.lightGrey};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.h5`
  left: 50%;
  margin: 0;
  padding: 16px 0;
  position: absolute;
  transform: translateX(-50%);
`;