import React, { useState, useCallback } from 'react';
import ReactGA from 'react-ga';

import {
  ADD_STORY_LOCALLY,
  BLUR_STAGE_LOCALLY,
  DELETE_STAGE_LOCALLY,
  FOCUS_STAGE_LOCALLY,
  FOCUS_STORY_LOCALLY,
  UPDATE_STAGE_LOCALLY,
  useStoreContext,
} from '../store';
import { Stage } from '../typings/stage';
import { createNewStory } from '../utils/story';
import { Props as StageDetailsProps, StageDetails } from './StageDetails';
import { PremiumModal } from '../components/PremiumModal';
import axios from 'axios';
import { isFreeAccount } from '../utils/users';

type Props = Omit<
  StageDetailsProps,
  | 'isFocus'
  | 'onAddStoryClick'
  | 'onStageBlur'
  | 'onStageDelete'
  | 'onStageTitleClick'
  | 'onStageUpdate'
>;

export const StageDetailsContainer: React.FC<Props> = ({ ...props }) => {
  const { state, dispatch } = useStoreContext();
  const [goPremiumVisible, setGoPremiumVisible] = useState(false);

  const handleAddStoryClick = useCallback(() => {
    const board = state.boards[props.stage.boardId]
    const lastStory = board.lastStoryId ? state.stories[board.lastStoryId] : null;
    const newStory = createNewStory(props.stage.id, {
      color: lastStory?.color || undefined,
    });
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    let stories1: any = localStorage.getItem('stories');
    const story1 = JSON.parse(stories1);

    if (isFreeAccount() && story1 >= 20) {
      setGoPremiumVisible(true);
      return;
    }
    dispatch({
      type: ADD_STORY_LOCALLY,
      payload: newStory,
    });
    dispatch({
      type: FOCUS_STORY_LOCALLY,
      payload: newStory,
    });
    ReactGA.event({
      category: 'task',
      action: 'taskCreated',
    });
  }, [props.stage.id, state.stories, dispatch]);

  const handleStageBlur = useCallback(() => {
    dispatch({
      type: BLUR_STAGE_LOCALLY,
      payload: {},
    });
  }, [dispatch]);

  const handleStageDelete = useCallback(
    (stage: Stage) => {
      dispatch({
        type: DELETE_STAGE_LOCALLY,
        payload: stage,
      });
      ReactGA.event({
        category: 'stage',
        action: 'stageDeleted',
      });
      localStorage.setItem('stories', '0');
    },
    [dispatch],
  );

  const handleStageTitleClick = useCallback(
    (stage: Stage) => {
      dispatch({
        type: FOCUS_STAGE_LOCALLY,
        payload: stage,
      });
    },
    [dispatch],
  );

  const handleStageUpdate = useCallback(
    (stage: Stage) => {
      if (
        props.stage.title &&
        stage.title === props.stage.title &&
        stage.priority === props.stage.priority
      ) {
        return;
      }
      dispatch({
        type: UPDATE_STAGE_LOCALLY,
        payload: stage,
      });
      ReactGA.event({
        category: 'stage',
        action: 'stageUpdated',
      });
    },
    [props.stage, dispatch],
  );

  const hidePremiumModal = () => {
    setGoPremiumVisible(false);
  };

  return (
    <div>
      <StageDetails
        {...props}
        isFocus={state.focusStageId === props.stage.id}
        onAddStoryClick={handleAddStoryClick}
        onStageBlur={handleStageBlur}
        onStageDelete={handleStageDelete}
        onStageTitleClick={handleStageTitleClick}
        onStageUpdate={handleStageUpdate}
      />
      {goPremiumVisible && <PremiumModal hidePremiumModal={hidePremiumModal} />}
    </div>
  );
};
