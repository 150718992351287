import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';

type Props = {
  type: 'SignIn' | 'SignUp';
  children: any;
  uiCallBack(showGoogleButton: boolean): void;
};

const TranslateAuth = ({ children, type, uiCallBack }: Props) => {
  const { i18n } = useTranslation();
  var isShowingEmailLoginForm = false;
  useEffect(() => {
    const observedDiv = document.getElementById('observer')!;
    const observer = new MutationObserver(function (mutationsList) {
      var showingEmailCheck = false;
      mutationsList.forEach(function (mutation) {
        const firebaseDiv = document.getElementById('observer');
        // console.log('FIREBASE DIV', firebaseDiv);
        if (firebaseDiv) {
          const hasText = (node: any) => {
            return (
              node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== ''
            );
          };

          const elWithText = Array.from(firebaseDiv.querySelectorAll('*')).filter(
            element => {
              const children = Array.from(element.childNodes);
              return children.some(child => hasText(child));
            },
          );

          elWithText.forEach(el => {
            if (
              i18n.exists(`Firebase.${type}.${el.innerHTML}`) &&
              i18n.t(`Firebase.${type}.${el.innerHTML}`) !== el.innerHTML
            ) {
              el.innerHTML = i18n.t(`Firebase.${type}.${el.innerHTML}`);
            }

            if (el.className.includes("firebaseui-title")) {
              showingEmailCheck = true;
            }
          });
        }
      });
      if (isShowingEmailLoginForm != showingEmailCheck) {
        isShowingEmailLoginForm = showingEmailCheck;
        uiCallBack(!isShowingEmailLoginForm);
      }
    });
    observer.observe(observedDiv, { childList: true, subtree: true });
  }, [i18n, type]);

  return <div id="observer">{children}</div>;
};

export default TranslateAuth;
