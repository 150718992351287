import { IDBPDatabase } from 'idb';

import { Board } from '../typings/board';
import { Database } from '../typings/database';
import { Stage } from '../typings/stage';
import { Story } from '../typings/story';

export const checkDBSupport = () => {
  if (!('indexedDB' in window)) {
    throw new Error('This browser does not support indexedDB');
  }
};

export const saveBoards = (
  database: IDBPDatabase<Database>,
  boards: Board[],
) => {
  const tx = database.transaction('boards', 'readwrite');
  const promises: Promise<any>[] = boards.map(board => tx.store.put(board));
  promises.push(tx.done);
  return Promise.all(promises);
};

export const saveStages = (
  database: IDBPDatabase<Database>,
  stages: Stage[],
) => {
  const tx = database.transaction('stages', 'readwrite');
  const promises: Promise<any>[] = stages.map(stage => tx.store.put(stage));
  promises.push(tx.done);
  return Promise.all(promises);
};

export const saveStories = (
  database: IDBPDatabase<Database>,
  stories: Story[],
) => {
  const tx = database.transaction('stories', 'readwrite');
  const promises: Promise<any>[] = stories.map(story => tx.store.put(story));
  promises.push(tx.done);
  return Promise.all(promises);
};
