export class GoPremiumQuote {
  quote: string;
  name: string;
  place: string;
  date: string;
  stars: number;

  constructor(
    quote: string,
    name: string,
    place: string,
    date: string,
    stars: number,
  ) {
    this.quote = quote;
    this.name = name;
    this.place = place;
    this.date = date;
    this.stars = stars;
  }
}
