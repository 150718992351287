// @flow
import React, { ChangeEvent, Component, FormEvent } from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import service from '../api/Service';
import {
  BackButton,
  Input,
  Logo,
  ModalContainer,
  ModalHeaderBar,
  SubmitButton,
} from '../styling/Components';
import { t } from 'i18next';

type Props = {
  callbackForgotPasswordEmailSent: () => void;
};

type State = {
  email: string;
};

export default class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { email: '' };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSendForgotPasswordEmail = this.handleSendForgotPasswordEmail.bind(
      this,
    );
  }

  handleSendForgotPasswordEmail(event: FormEvent<HTMLFormElement>) {
    service.sendForgotPasswordEmail(
      this.state.email,
      () => {
        alert(
          'Please visit your email inbox and follow the instructions. If you encounter any problems please contact support@kanbana.com',
        );
        this.props.callbackForgotPasswordEmailSent();
        ReactGA.event({
          category: 'password',
          action: 'ForgotPasswordEmailSent',
        });
      },
      failed => {
        alert(failed);
      },
    );

    event.preventDefault();
  }

  handleChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ email: event.target.value });
  }

  handleBack = () => {
    this.props.callbackForgotPasswordEmailSent();
  };

  render() {
    //todo pr1: if using enter then error: Form submission canceled because the form is not connected
    return (
      <ModalContainer>
        <ModalHeaderBar>
          <BackButton onClick={this.handleBack} />
        </ModalHeaderBar>
        <Logo />
        <h2>{t('Auth.ForgotPassword')}</h2>
        <form onSubmit={this.handleSendForgotPasswordEmail}>
          <FormContainer>
            <Input
              autoFocus
              placeholder="email"
              value={this.state.email}
              onChange={this.handleChangeEmail}
            />
            <SubmitButton type="submit" value="Send Reset Email" />
          </FormContainer>
        </form>
      </ModalContainer>
    );
  }
}

const FormContainer = styled.div`
  text-align: 'center';
`;
