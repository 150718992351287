import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import check from '../assets/animation/check.json'
import styled from 'styled-components';
import { defaultLottieConfig } from '../utils/lottie';

type Props = {
  complete: boolean;
  open: boolean;
}

export const EnjoyFullExpModal = ({ complete, open }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (complete) {
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  }, [complete]);

  return <Modal width={500} open={open} footer={false} title={false} closable={false}>
    <h2 style={{ textAlign: 'center' }}>{t('EnjoyFullExp.Title')}</h2>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Text>{t('EnjoyFullExp.Text1')}</Text>
      <Text>{t('EnjoyFullExp.Text2')}</Text>
      <Text>{t('EnjoyFullExp.Text3')}</Text>
      <Label>{t('EnjoyFullExp.NotCloseBrowser')}</Label>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 60 }}>
        <div style={{ position: 'absolute', bottom: 30 }}>
          {loading ?
            <LoadingOutlined style={{ width: 40, fontSize: 40, color: '#d9e0e6' }} /> :
            <Lottie style={{ marginTop: -10 }} options={defaultLottieConfig(check)}
              height={56}
              width={56}
              isStopped={false}
              isPaused={false} />}

        </div>
      </div>
    </div>
  </Modal>
}

const Text = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 400;
`;


const Label = styled.label`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  padding-top: 16px;
`;