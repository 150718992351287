import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ConfigProvider, Form, Input, Modal } from 'antd';
import { ModalContainer } from '../styling/Components';
import { colors } from '../theme';

type Props = {
  open?: boolean,
  onDismiss: () => void;
  onSubmit: (boardTitle: string) => void;
};

export const AddBoardModal: React.FC<Props> = ({ open, onDismiss, onSubmit }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (data: any) => {
      onSubmit(data.title)
    },
    [onSubmit],
  );

  return (
    <Modal destroyOnClose footer={false} onCancel={onDismiss} open={open}>
      <Form onFinish={handleSubmit}>
        <ModalContainer style={{ color: colors.black }}>
          <h2>{t('newModal')}</h2>
          <div>
            {t('newModalContent')}
            <Form.Item
              name="title"
              rules={[{ required: true, message: t('CopyBoard.Error').toString() }]}
            >
              <Input />
            </Form.Item>
            <Button htmlType='submit' style={{ width: '100%' }}>
              {t('newModalCreate')}
            </Button>
          </div>
        </ModalContainer>
      </Form>
    </Modal>
  );
};