import * as firebase from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from "firebase/auth";
import 'firebase/analytics';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './i18n/config'

import { AppContainer } from './App.container';
import { SharedBoardContainer } from './board/SharedBoard.container';
import { DatabaseProvider } from './database/DatabaseProvider';
import { StoreProvider } from './store';
import './index.css';
import FastSpringProvider from './store/FastSpringContext';

class Main extends React.Component {
  render() {
    return (
      <DatabaseProvider>
        <FastSpringProvider>
          <StoreProvider>
            <BrowserRouter>
              <Switch>
                <Route path="/shared/:token" component={SharedBoardContainer} />
                <Route path="/login" component={AppContainer} />
                <Route path="/signup" component={AppContainer} />
                <Route path="/board" component={AppContainer} />
                <Route path="/" component={AppContainer} />
              </Switch>
            </BrowserRouter>
          </StoreProvider>
        </FastSpringProvider>
      </DatabaseProvider>
    );
  }
}

const firebaseConfig = {
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
};

var fire = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(fire);
export const auth = getAuth(fire);

export default fire;

ReactDOM.render(<Main />, document.getElementById('root'));
