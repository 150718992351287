import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import i18n from 'i18next';
import { LanguageSelector } from './LanguageSelector';
import { GetLanguageMetadata, Language } from '../utils/lang';

import imgLogoFull from '../resources/images/new_logo.png';
import { useTranslation } from 'react-i18next';
import { buildKanbanaRedirectUrl } from '../utils/externalLink';
import { Locale } from 'antd/lib/locale';
import { useIntercom } from 'react-use-intercom';

interface SignInNavbarProps {
  isWeb: boolean;
  handleClickLogIn: () => void;
  handleClickSignUp: () => void;
}

export const SignInNavbar = (props: SignInNavbarProps) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<Language>(GetLanguageMetadata(navigator.language));
  const { update } = useIntercom();

  useEffect(() => {
    let lang = localStorage.getItem('lang') || navigator.language;
    if (GetLanguageMetadata(lang).langFullName === '') {
      lang = 'en';
    }
    const { langFlg, langFullName, currentAntdLocale, langShortValue } = GetLanguageMetadata(lang);
    update({ languageOverride: langShortValue });
    activeLanguage(lang, langFlg, langFullName, currentAntdLocale, langShortValue);
  }, []);

  const activeLanguage = (lang: string, flag: string, fullName: string, antdLocale: Locale, langShortValue: string) => {
    setLanguage({ lang, langFlg: flag, langFullName: fullName, currentAntdLocale: antdLocale, langShortValue });
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    update({ languageOverride: langShortValue });
  };

  if (props.isWeb) {
    return (
      <SignUpNavbar>
        <LeftGroup>
          <KanbanaLogo
            onClick={() => {
              window.location.href = '/';
            }}
          />
          <NavButton
            onClick={() => {
              window.location.href = '/';
            }}>
            {t('Navbar.Home')}
          </NavButton>
          <NavButton
            onClick={() => {
              window.open(buildKanbanaRedirectUrl(language.lang, '/about_us'), '_blank');
            }}>
            {t('Navbar.AboutUs')}
          </NavButton>
          <NavButton
            onClick={() => {
              window.open(buildKanbanaRedirectUrl(language.lang, '/help_center'), '_blank');
            }}>
            {t('Navbar.HelpCenter')}
          </NavButton>
          {/* <HelpCenterDropdown /> */}
          <NavButton
            onClick={() => {
              window.open(buildKanbanaRedirectUrl(language.lang, '/pricing'), '_blank');
            }}>
            {t('Navbar.Pricing')}
          </NavButton>
          <LanguageSelector {...language} activelanguage={activeLanguage} />
        </LeftGroup>

        <RightGroup>
          <NavButton onClick={props.handleClickLogIn}>
            {t('Navbar.LogIn')}
          </NavButton>
          <SignUpButton onClick={props.handleClickSignUp}>
            {t('Navbar.SignUpForFree')}
          </SignUpButton>
        </RightGroup>
      </SignUpNavbar>
    );
  }
  else {
    return (
      <SignUpNavbar>
        <LanguageSelector {...language} activelanguage={activeLanguage} />
      </SignUpNavbar>
    );
  }
};

const SignUpNavbar = styled.div`
  margin: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

const LeftGroup = styled.div`
  display: flex;
`;

const RightGroup = styled.div`
  display: flex;
`;

const KanbanaLogo = styled.div`
  background-image: url(${imgLogoFull});
  max-height: 100%;
  width: 200px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  margin-right: 15px;
`;

const NavButton = styled.div`
  flex: 0 0 auto;
  padding: 10px 16px 10px 16px;
  font-weight: bold;
  height: 18px;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;

const SignUpButton = styled.button`
  color: var(--base-white, #ffffff);
  border: 1px solid var(--picton-blue-600, #009bd3);
  border-radius: 8px;
  background-color: var(--picton-blue-500, #01b6e9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  flex: 0 0 auto;
  padding: 10px 16px 10px 16px;
  font-weight: bold;
  height: 18px;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  height: 40px;
`;

export default SignInNavbar;
