import React from 'react';
import { Button, Divider, Modal } from "antd";
import { useTranslation } from 'react-i18next';
import { TEMPLATES } from '../utils/template';
import { TFunction } from 'i18next';
import { styled } from 'styled-components';

type Props = {
  open: boolean,
  onCancel: () => void,
  selectTemplate: (template: string) => void,
  onAddBoardClick: () => void
}

export const TemplateModal = ({ open, onCancel, selectTemplate, onAddBoardClick }: Props) => {
  const { t } = useTranslation();

  return <Modal onCancel={onCancel} open={open} footer={false} title={false}>
    {/* <div style={{ backgroundColor: '#DEF5FF', padding: 0 }}> */}
    <h1 style={{ textAlign: 'center' }}>{t('Template.Choose')}</h1>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ButtonContainer>
        <TemplateButton mediaPath={TEMPLATES[0].mediaPath} mediaAlt={TEMPLATES[0].mediaAlt} t={t} templateId={TEMPLATES[0].id} onClick={() => selectTemplate(TEMPLATES[0].id)} />
        <TemplateButton mediaPath={TEMPLATES[1].mediaPath} mediaAlt={TEMPLATES[1].mediaAlt} t={t} templateId={TEMPLATES[1].id} onClick={() => selectTemplate(TEMPLATES[1].id)} />
      </ButtonContainer>
      <ButtonContainer>
        <TemplateButton mediaPath={TEMPLATES[2].mediaPath} mediaAlt={TEMPLATES[2].mediaAlt} t={t} templateId={TEMPLATES[2].id} onClick={() => selectTemplate(TEMPLATES[2].id)} />
        <TemplateButton mediaPath={TEMPLATES[3].mediaPath} mediaAlt={TEMPLATES[3].mediaAlt} t={t} templateId={TEMPLATES[3].id} onClick={() => selectTemplate(TEMPLATES[3].id)} />
      </ButtonContainer>
      <ButtonContainer style={{ justifyContent: 'center' }}>
        <TemplateButton mediaPath={TEMPLATES[4].mediaPath} mediaAlt={TEMPLATES[4].mediaAlt} t={t} templateId={TEMPLATES[4].id} onClick={() => selectTemplate(TEMPLATES[4].id)} />
      </ButtonContainer>
      <Divider>{t('Template.OrContinueWith')}</Divider>
      <Button onClick={() => {
        onCancel();
        onAddBoardClick();
      }} size='large'>{t('Template.CreateOwn')}</Button>
    </div>
    {/* </div> */}
  </Modal >
}

interface TemplateButtonProps {
  mediaPath: string;
  mediaAlt: string;
  t: TFunction<"translation", undefined, "translation">;
  templateId: string;
  onClick(): void;
}

const TemplateButton = ({ mediaPath, mediaAlt, templateId, t, onClick }: TemplateButtonProps) => {
  return (
    <TemplateButtonContainer onClick={onClick}>
      <ButtonImg src={mediaPath} alt={mediaAlt} width="55" height="55" />
      <ButtonText>{t(`Template.${templateId}`)}</ButtonText>
    </TemplateButtonContainer>
  )
}

const ButtonImg = styled.img`
  position: absolute;
  top: -20px
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px
`;

const ButtonText = styled.div`
border-width: 1px;
border: 1px solid #cecece;
width: 100%;
display: flex;
height: 30px;
justify-content: center;
align-items: flex-end;
border-radius: 20px;
padding-bottom: 5px;
`;

const TemplateButtonContainer = styled(Button)`
  width: 40%;
  display: flex;
  flex-direction: column;
  border-width: 0px;
  height: 90px;

  &:hover {
    border-color: #4096ff;
  }
`;