import React, { Component, KeyboardEvent, RefObject } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import ReactLinkify from 'react-linkify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Textarea } from '../common/Textarea';
import {
  Link,
} from '../styling/Components';
import { borderRadius, colors, sizes } from '../theme';
import { Story } from '../typings/story';
import { BLUE_LIST, changeLightness } from '../utils/colors';
import { BiAlarm } from "react-icons/bi";

import Lottie from 'react-lottie';
import { defaultLottieConfig } from '../utils/lottie';
import check from '../assets/animation/cardCheck.json';
import blackCardCheck from '../assets/animation/blackCardCheck.json';
import trash from '../assets/animation/Trash.json';
import blackTrash from '../assets/animation/BlackTrash.json';
import Icon from '../components/Icon';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, isNil, orderBy } from 'lodash';
import { Button, Popover, Select, DatePicker } from 'antd';
import { Stage } from '../typings/stage';
import { Board } from '../typings/board';
import { TFunction } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { CheckOutlined, ClearOutlined } from '@ant-design/icons';
import { LogEvent } from '../utils/analytics';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

type Props = {
  isDragging: boolean;
  isFocus?: boolean;
  provided: DraggableProvided;
  readOnly?: boolean;
  story: Story;
  isBoldColors: boolean;
  allActivesStages: Stage[];
  allActivesBoards: Board[];
  t: TFunction<"translation", undefined, "translation">;
  handleMoveCard?: (story: Story) => void;
  onAdd: (stageId: string, index: number) => void;
  onBlur: (story?: Story) => void;
  onChange: (story: Story) => void;
  onDelete: (story: Story) => void;
  onComplete: (story: Story) => void;
  onFocus: (story: Story) => void;
};

type State = {
  colorOptions: string[];
  story: Story;
  imageColor: string;
  completeCheck: boolean;
  deleteCheck: boolean;
  showMoveThisCard: boolean;
  stage?: string;
  board?: string;
  deadline?: Dayjs;
  openCalendar: boolean;
};

export default class StoryItem extends Component<Props, State> {
  wrapperRef: RefObject<HTMLDivElement>;
  calendarRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      colorOptions: props.isBoldColors
        ? colors.taskOptionsBold
        : colors.taskOptionsLight,
      story: props.story, // save it to state to reduce the impact on performance (just like before)
      imageColor: 'black',
      completeCheck: false,
      deleteCheck: false,
      showMoveThisCard: true,
      stage: undefined,
      board: undefined,
      deadline: props.story.deadlineAt ? dayjs(props.story.deadlineAt) : undefined,
      openCalendar: false
    };
    this.wrapperRef = React.createRef();
    this.calendarRef = React.createRef();
  }

  // update component state when there are changes to the props
  static getDerivedStateFromProps(
    props: Props,
    state: State,
  ): Partial<State> | null {
    if (
      props.story.updatedAt?.toISOString() !==
      state.story.updatedAt?.toISOString()
    ) {
      return {
        story: props.story,
      };
    }
    return null;
  }

  handleColorChange = (color: string) => {
    const { story } = this.state;
    color = convertToStoredColor(color, this.props.isBoldColors);
    this.setState({
      story: {
        ...story,
        color,
      },
    });
  };

  handleDelete = () => {
    this.setState({ deleteCheck: true });
    setTimeout(() => {
      this.props.onDelete(this.state.story);
      let data: any = localStorage.getItem('AccountInfo');
      const accountInfo = JSON.parse(data);
      accountInfo['story'] = parseInt(accountInfo['story']) - 1;
      localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
    }, 2000);
  };

  handleComplete = () => {
    this.setState({ completeCheck: true });
    setTimeout(() => {
      this.props.onComplete(this.state.story);
      let data: any = localStorage.getItem('AccountInfo');
      const accountInfo = JSON.parse(data);
      accountInfo['story'] = parseInt(accountInfo['story']) - 1;
      localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
    }, 1500);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    const { isFocus, onAdd, onBlur, onDelete, onFocus } = this.props;
    const { story } = this.state;

    // Using KeyboardEvent.key instead of KeyboardEvent.keyCode because keyCode is deprecated
    //CTRL+I
    if (event.ctrlKey && event.key === 'i') {
      onAdd(story.stageId, story.priority);
      //CTRL+Enter
    } else if (event.shiftKey && event.key === 'Enter') {
      const textArea = event.target as HTMLTextAreaElement;
      const cursorPosition = textArea.selectionStart;
      const updatedStory = { ...story };
      const title = updatedStory.title;
      // updatedStory.title = [
      //   // title.slice(0, cursorPosition),
      //   // title.slice(cursorPosition),
      // ].join('');
      //Enter
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (isFocus) {
        this.save();
      } else {
        onFocus(story);
      }

      //Delete (fn+backspace)
    } else if (!isFocus && event.key === 'Delete') {
      onDelete(story);
      //Escape should cancel editing
    } else if (isFocus && event.key === 'Escape') {
      //cancel editing of new task by deleting it
      if (story.createdAt === undefined) {
        onDelete(story);
        //cancel editing of existing task by restoring the original title and leave edit mode
      } else {
        this.setState({
          story: this.props.story,
        });
        onBlur();
      }
    }
  };

  save = () => {
    const timestamp = new Date();
    const { story } = this.state;

    this.props.onBlur({
      ...story,
      createdAt: story.createdAt || timestamp,
      updatedAt: story.updatedAt || timestamp,
    });
  }

  handleOutsideClick = (event: MouseEvent) => {
    const { isFocus, onBlur, onChange } = this.props;

    const div = event.target as HTMLDivElement;
    const className = (isNil(div) || isNil(div.className)) ? "" : div.className;

    if (typeof className === "string" && !className?.includes('ant-select-item-option') && !className?.startsWith('ant-picker')) {
      const node = this.wrapperRef.current ?? this.calendarRef.current;

      if (isFocus && node && !node.contains(event.target as Node)) {
        this.setState({
          showMoveThisCard: true,
          board: undefined,
          stage: undefined,
          openCalendar: false,
          deadline: this.state.story.deadlineAt ?
            dayjs(this.state.story.deadlineAt) : undefined
        });

        onChange({
          ...this.state.story,
        });
        onBlur();
      }
    }
  };

  handleStoryClick = () => {
    const { isFocus, onFocus } = this.props;
    if (!isFocus) {
      onFocus(this.state.story);
    }
  };

  handleTitleChange = (title: string) => {
    const { story } = this.state;
    this.setState({
      story: {
        ...story,
        title,
      },
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  render() {
    const {
      isDragging,
      isFocus,
      provided,
      readOnly,
      isBoldColors,
      onChange,
    } = this.props;
    const { story } = this.state;

    let content = null;
    if (isFocus && !readOnly) {
      content = (
        <EditModeWrapper>
          {!this.state.deleteCheck ?
            <div>
              <TrashStyled>
                <Icon fontSize={36} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleDelete();
                }} name='cardTrash' style={{ color: isBoldColors ? "white" : 'black' }}
                />
              </TrashStyled>
              <SaveStyled>
                <FontAwesomeIcon
                  icon={faCheck}
                  color={isBoldColors ? "white" : 'black'}
                  style={{ fontSize: '15px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.save();
                  }}
                />
              </SaveStyled>
            </div>
            :
            <LottieTrashStyled>
              <Lottie
                style={{
                  color: 'white', fill: 'white', stroke: 'white'
                }}
                options={defaultLottieConfig(isBoldColors ? trash : blackTrash)}
                height={36}
                width={36}
                isStopped={false}
                isPaused={false}
              />
            </LottieTrashStyled>
          }
          <Textarea
            autoFocus
            rows={5}
            target="task"
            value={story.title}
            color={getTextColor(isBoldColors)}
            onChange={this.handleTitleChange.bind(this)}
            style={{
              backgroundColor: 'white',
              width: '90%',
              display: 'flex',
              alignSelf: 'center',
              marginBottom: '10px',
              borderRadius: '10px',
              fontSize: '14px',
            }}
          />
          <div>
            <ColorList>
              {Object.values(
                isBoldColors ? colors.taskOptionsBold : colors.taskOptionsLight,
              ).map(x => (
                <ColorListItem
                  colorOptions={
                    isBoldColors
                      ? colors.taskOptionsBold
                      : colors.taskOptionsLight
                  }
                  isbold={isBoldColors}
                  color={x}
                  onClick={() => this.handleColorChange(x)}
                >{
                    convertToDisplayColor(story.color, isBoldColors) === convertToDisplayColor(x, isBoldColors) && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={isBoldColors ? "white" : 'black'}
                        style={{ fontSize: '15px' }}
                      />
                    )
                  }
                </ColorListItem>
              ))}
            </ColorList>
            <ButtonDivStyled style={{ display: this.state.showMoveThisCard && !isEmpty(story.title) ? 'flex' : 'none' }}>
              <Button type={BLUE_LIST.includes(convertToDisplayColor(story.color, isBoldColors)) ? 'default' : 'primary'} onClick={() => this.setState({ showMoveThisCard: false })} style={{ width: 206 }}>{this.props.t('Story.MoveCard')}</Button>
            </ButtonDivStyled>
            <span style={{
              display: !this.state.showMoveThisCard ? 'flex' : 'none',
              justifyContent: 'center',
              marginTop: '10px',
            }}>
              {this.props.t('Story.MoveCardsTitle')}
            </span>
            <MoveSelectDivStyled style={{ display: !this.state.showMoveThisCard ? 'flex' : 'none' }}>
              <Select value={this.state.board} onChange={(value) => this.setState({ board: value, stage: undefined })} placeholder={this.props.t('Story.SelectBoard')} style={{ width: 206 }}
                options={orderBy(this.props.allActivesBoards, 'priority').map(x => ({
                  label: x.title,
                  value: x.id
                }))}
              />
              <Select value={this.state.stage} onChange={(value) => this.setState({ stage: value })} disabled={this.state.board == null}
                placeholder={this.props.t('Story.SelectStage')} style={{ width: 206 }}
                options={orderBy(this.props.allActivesStages, 'priority').filter(x => x.boardId === this.state.board).map(x => ({
                  label: x.title,
                  value: x.id
                }))}
              />
              <Button disabled={this.state.stage == null} type={BLUE_LIST.includes(convertToDisplayColor(story.color, isBoldColors)) ? 'default' : 'primary'} onClick={() => {
                this.props.handleMoveCard && this.props.handleMoveCard({ ...story, priority: 0, stageId: this.state.stage! })
              }} style={{ width: 206 }}>{this.props.t('Story.Move')}</Button>
              <Button onClick={() => this.setState({ showMoveThisCard: true })} style={{ width: 206 }}>{this.props.t('Auth.Cancel')}</Button>
            </MoveSelectDivStyled>
          </div>
        </EditModeWrapper >
      );
    } else {
      content = (
        <Text style={{
          marginRight: '30px',
          fontSize: '14px',
          wordWrap: 'break-word'
        }}>
          {story.title}
          {(!isFocus && this.state.deadline) &&
            <DeadlineStyled>
              <BiAlarm /> <span style={{ marginLeft: 3 }}>{this.state.deadline.format('MMM DD, YYYY')}</span>
            </DeadlineStyled>
          }
        </Text>
      );
    }

    return (
      <div
        style={{
          opacity: story.completedAt == null ? 1 : 0.4,
          position: 'relative',
        }}
        ref={this.wrapperRef}
        onClick={this.handleStoryClick}
        onKeyDown={this.handleKeyDown}>
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Content
            style={{ color: `${getTextColor(isBoldColors)}` }}
            color={convertToDisplayColor(story.color, isBoldColors)}
            isbold={isBoldColors}
            isDragging={isDragging}
            readOnly={readOnly}>
            <ReactLinkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <Link
                  href={decoratedHref}
                  key={key}
                  style={{ color: `${getTextColor(isBoldColors)}` }}
                  target="_blank"
                  onClick={e => {
                    e.stopPropagation();
                    LogEvent('linkClicked');
                    if (window.confirm) {
                      const confirmed = window.confirm(
                        `Are you sure you want to open this link? (${decoratedHref})`,
                      );
                      if (!confirmed) {
                        e.preventDefault();
                      }
                    }
                  }}>
                  {decoratedText}
                </Link>
              )}>
              {!story.completedAt && (!isFocus && (!this.state.completeCheck && isNil(story.completedAt) ?
                <CheckStyled
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleComplete();
                  }}>
                  <div
                    style={{
                      border: `1px solid ${isBoldColors ? '#FFFFFF' : '#000000'
                        }`,
                      borderRadius: '50%',
                      height: '16px',
                      width: '16px',
                      cursor: 'pointer',
                    }}></div>
                </CheckStyled>
                :
                <LottieCheckStyled>
                  <Lottie
                    options={defaultLottieConfig(isBoldColors ? check : blackCardCheck)}
                    height={20}
                    width={20}
                    isStopped={false}
                    isPaused={false}
                  />
                </LottieCheckStyled>
              ))}
              {story.completedAt && (!isFocus && (!this.state.completeCheck && !isNil(story.completedAt) &&
                <LottieCheckStyled onClick={() => undefined}>
                  <Lottie
                    options={{ ...defaultLottieConfig(isBoldColors ? check : blackCardCheck) }}
                    height={20}
                    width={20}
                    isClickToPauseDisabled={true}
                  />
                </LottieCheckStyled>
              ))}
              {(!story.completedAt && !story.deletedAt && isFocus) &&
                <Popover
                  onOpenChange={(value) => this.setState({ openCalendar: value })}
                  open={this.state.openCalendar}
                  getPopupContainer={(trigger: any) => trigger.parentElement}
                  trigger='click'
                  style={{
                    height: '16px',
                    width: '16px',
                    cursor: 'pointer',
                  }}
                  content={
                    <CalendarDiv ref={this.calendarRef}>
                      <CalendarTitle>{this.props.t('Card.ChooseADeadline_Title')}</CalendarTitle>
                      <DatePicker
                        disabledDate={(value) => value.startOf('day') <= dayjs().add(-1, "day").startOf('day')}
                        onChange={(value) => {
                          if (isNil(value)) {
                            this.setState({ deadline: undefined, openCalendar: false });
                            onChange({ ...story, deadlineAt: undefined });
                          } else {
                            this.setState({ deadline: value as Dayjs });
                          }
                        }}
                        defaultValue={isNil(story?.deadlineAt) ? null : dayjs(story?.deadlineAt)}
                        style={{ maxWidth: 300, display: 'flex', justifyContent: 'center', marginBottom: 15 }}
                        mode='date'
                        format={{
                          format: 'MMM DD, YYYY'
                        }}
                        placeholder={this.props.t('Card.ChooseADeadline_Placeholder') ?? ''}
                      />
                      <CalendarBtns>
                        <Button
                          onClick={() => {
                            onChange({ ...story, deadlineAt: this.state.deadline?.toDate() });
                            this.setState({ openCalendar: false });
                          }}
                          disabled={isNil(this.state.deadline)}
                          size='small' type='primary'><CalendarBtn><CheckOutlined />{this.props.t('Text.Save') ?? ''}</CalendarBtn></Button>
                        <Button onClick={() => this.setState({ openCalendar: false, deadline: dayjs(story.deadlineAt) })} size='small'>{this.props.t('Text.Cancel') ?? ''}</Button>
                      </CalendarBtns>
                    </CalendarDiv>}>
                  <LottieClockStyled>
                    <BiAlarm color={isNil(story?.deadlineAt) ? (isBoldColors ? 'white' : 'black') : (isBoldColors && convertToDisplayColor(story.color, isBoldColors) === '#E90101') ? 'black' : 'red'} />
                  </LottieClockStyled>
                </Popover>
              }

              {story.completedAt &&
                !isFocus &&
                !this.state.completeCheck && !isNil(story.completedAt) && (
                  <LottieCheckStyled onClick={() => undefined}>
                    <Lottie
                      options={{
                        ...defaultLottieConfig(
                          isBoldColors ? check : blackCardCheck,
                        ),
                      }}
                      height={20}
                      width={20}
                      isClickToPauseDisabled={true}
                    />
                  </LottieCheckStyled>
                )}

              {content}
            </ReactLinkify>
          </Content>
        </Container>
      </div>
    );
  }
}

const getTextColor = (isBoldColors: boolean): string => {
  return isBoldColors ? colors.white : colors.black;
};

const convertToStoredColor = (
  displayColor: string,
  isBoldColors: boolean,
): string => {
  for (let [k, v] of getColorStorageMap(isBoldColors)) {
    if (v === displayColor.toUpperCase()) {
      return k;
    }
  }
  return displayColor.toUpperCase();
};

const convertToDisplayColor = (
  storedColor: string,
  isBoldColors: boolean,
): string => {
  let colorMap = getColorDisplayMap(isBoldColors);
  return colorMap.get(storedColor.toUpperCase()) || storedColor.toUpperCase();
};

const getColorDisplayMap = (isBoldColors: boolean): Map<string, string> => {
  let colorMap = new Map<string, string>();
  if (isBoldColors) {
    colorMap.set('#F9F7F7', '#888888');
    colorMap.set('#EF5350', '#E8731E');
    colorMap.set('#FF6D00', '#E8731E');
    colorMap.set('#FEFE50', '#FBC30E');
    colorMap.set('#01FB2B', '#6DAD35');
    colorMap.set('#64B5F6', '#01B6E9');
    colorMap.set('#D8A5EA', '#A335AD');
    colorMap.set('#748182', '#454545');
    colorMap.set('#B63028', '#E90101');
    colorMap.set('#CB4A12', '#E90101');
    colorMap.set('#EF9423', '#FBC30E');
    colorMap.set('#2BC96A', '#6DAD35');
    colorMap.set('#1C977A', '#6DAD35');
    colorMap.set('#2C71AE', '#195893');
    colorMap.set('#8430A1', '#A335AD');
    colorMap.set('#6B4030', '#95520B');
  } else {
    colorMap.set('#F9F7F7', '#F6F6F6');
    colorMap.set('#EF5350', '#FFEDE0');
    colorMap.set('#FF6D00', '#FFEDE0');
    colorMap.set('#FEFE50', '#FFFEEA');
    colorMap.set('#01FB2B', '#F3FAEB');
    colorMap.set('#64B5F6', '#DEF5FF');
    colorMap.set('#D8A5EA', '#FDF4FF');
    colorMap.set('#748182', '#E7E7E7');
    colorMap.set('#B63028', '#FFDEDE');
    colorMap.set('#CB4A12', '#FFDEDE');
    colorMap.set('#EF9423', '#FFFEEA');
    colorMap.set('#2BC96A', '#F3FAEB');
    colorMap.set('#1C977A', '#F3FAEB');
    colorMap.set('#2C71AE', '#EEF0FD');
    colorMap.set('#8430A1', '#FDF4FF');
    colorMap.set('#6B4030', '#FFF0DF');
  }
  return colorMap;
};
const getColorStorageMap = (isBoldColors: boolean): Map<string, string> => {
  let colorMap = new Map<string, string>();
  if (isBoldColors) {
    colorMap.set('#F9F7F7', '#888888');
    colorMap.set('#FF6D00', '#E8731E');
    colorMap.set('#FEFE50', '#FBC30E');
    colorMap.set('#64B5F6', '#01B6E9');
    colorMap.set('#748182', '#454545');
    colorMap.set('#B63028', '#E90101');
    colorMap.set('#2BC96A', '#6DAD35');
    colorMap.set('#2C71AE', '#195893');
    colorMap.set('#8430A1', '#A335AD');
    colorMap.set('#6B4030', '#95520B');
  } else {
    colorMap.set('#F9F7F7', '#F6F6F6');
    colorMap.set('#FF6D00', '#FFEDE0');
    colorMap.set('#FEFE50', '#FFFEEA');
    colorMap.set('#64B5F6', '#DEF5FF');
    colorMap.set('#748182', '#E7E7E7');
    colorMap.set('#B63028', '#FFDEDE');
    colorMap.set('#2BC96A', '#F3FAEB');
    colorMap.set('#2C71AE', '#EEF0FD');
    colorMap.set('#8430A1', '#FDF4FF');
    colorMap.set('#6B4030', '#FFF0DF');
  }
  return colorMap;
};
const getLightBorderColor = (hexValue: string): string => {
  // Find the index of the hex value in taskOptionsLight
  const index = colors.taskOptionsLight.indexOf(hexValue);

  // If the hex value is found, return the corresponding value from taskOptionsLightBorder
  if (index !== -1) {
    return colors.taskOptionsLightBorder[index];
  } else {
    // Handle the case when the hex value is not found (e.g., return a default value or handle accordingly)
    console.error('Hex value not found in taskOptionsLight:', hexValue);
    return "none"; // You can return a default value or handle the case accordingly
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 7px;
  border-radius: ${borderRadius}px;
  /* preserve rounded corners in child elements */

  &:focus {
    outline: 1px solid ${colors.focusOutline};
    outline-offset: 3px;
  }
`;

type ContentProps = {
  isDragging: boolean;
  readOnly?: boolean;
  isbold?: boolean
};

const Content = styled.div<ContentProps>`
  @keyframes jiggle {
    40% {
      transform: rotate(1deg);
    }
    80% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  position: relative;
  display: flex;
  min-height: 40px;
  border-radius: ${borderRadius}px;
  transition: background-color 0.2s ease;
  background-color: ${props => props.color};
  outline: 1px solid ${props => props.isbold ? 'none' : getLightBorderColor(props.color || '#ffffff')};
  box-shadow: ${({ isDragging }) =>
    isDragging ? `3px 3px 2px ${colors.shadow}` : 'none'};
  animation: ${({ isDragging }) =>
    isDragging ? `jiggle 0.25s infinite` : 'none'};
  animation-timing-function: linear;
  user-select: none;
  cursor: ${props =>
    props.readOnly ? `default` : props.isDragging ? 'grabbing' : 'grab'};
`;

const Text = styled.div`
  display: block;
  padding: ${sizes.story.padding}px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
`;

const EditModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-radius: ${borderRadius}px;
  padding-top: 30px;
`;

const ColorList = styled.div`
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
  display: grid;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 12px;
  border-radius: 6px;
  column-gap: 10px;
  width: 194px;
  height: 82px;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-around;
  overflow: hidden;
  background-color: white;
`;

type ColorListItemProps = {
  colorOptions: string[];
  isbold: boolean;
};

const ColorListItem = styled.button<ColorListItemProps>`
  background: ${props => props.color};
  padding: 0px;
  color: 'red';
  width: 29px;
  max-width: 29px;
  height: 29px;
  border: 0px;
  outline: 1px solid ${props => props.isbold ? 'none' : getLightBorderColor(props.color || '#ffffff')};
  outline-offset: -1px;

  &:focus {
    outline: 2px solid ${props => changeLightness(props.color || '#ffffff', props.isbold ? 1.5 : 0.5)} !important;
    outline-offset: -2px;
  }
`;

const TrashStyled = styled.div`
  position: absolute;
  top: -5px;
  left: -1px;
  cursor: pointer;
`;

const SaveStyled = styled.div`
  position: absolute;
  right: 12px;
  top: 5px;
  cursor: pointer;
`

const CheckStyled = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  background-color: transparent;
  padding: 3px;
`;

const LottieCheckStyled = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const LottieTrashStyled = styled.div`
  position: absolute;
  left: -1px;
  top: -5px;
`;

const ButtonDivStyled = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const MoveSelectDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  gap: 12px;
  .ant-btn-primary:disabled,
  .ant-select-disabled {
    background-color: #ffffff !important;
    opacity: 0.7;
    border-radius: 6px;
  }
`;

const LottieClockStyled = styled.div`
  position: absolute;
  right: 35px;
  top: 4px;
  z-index: 999;
  cursor: pointer;
`;

const CalendarDiv = styled.div`
  .ant-radio-group-outline {
    display: none;
  }
`;

const CalendarBtns = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-around;
`;

const CalendarBtn = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CalendarTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const DeadlineStyled = styled.div`
  padding-top: 8px;
  font-size: 10px;
  display: flex;
`;