// @flow
import React, { ChangeEvent, Component } from 'react';
import { styled } from 'styled-components';
import ReactGA from 'react-ga';
import i18n from 'i18next';
import service from '../api/Service';
import { SignInViewModel } from './SignInViewModel';
import { withTranslation } from 'react-i18next';
import SignInNavbar from '../components/SignInNavbar';
import ImageTestimonial from '../components/ImageTestimonial';

import Testimonials from '../resources/Testimonials';
import media1 from '../resources/images/testimonial_image_1.jpg';
import media2 from '../resources/images/testimonial_image_2.jpg';
import TranslateAuth from './TranslateAuth';
import { CredentialResponse } from 'google-one-tap';

import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { buildKanbanaRedirectUrl } from '../utils/externalLink';
import { auth } from '..';
import { EmailAuthProvider, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import StyledFirebaseAuth from '../components/StyledFirebaseAuth';
import { TermsAndConditions } from '../components/TermsAndConditions';

type State = {
  viewModel: SignInViewModel;
  signIn: boolean;
  signUp: boolean;
  loading: boolean;
  name: string;
  email: string;
  password: string;
  gsiInit: boolean;
};

type Props = {
  callbackSignInSucceeded: (accountId: string) => void;
  callbackOpenForgotPassword: () => void;
  onSignOutClick?: () => void;
  onLogInClick: () => void;
  t?: any;
};

class SignUp extends Component<Props, State> {
  uiConfig: any;
  componentWillMount() {
    this.setState({ viewModel: new SignInViewModel() });
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      viewModel: new SignInViewModel(),
      signIn: true,
      signUp: false,
      loading: true,
      name: '',
      email: '',
      password: '',
      gsiInit: false
    };
    let signInFlow = isDesktopApp() ? 'redirect' : 'popup';
    let signInOptions = [
      'apple.com',
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ];
    if (!isDesktopApp()) {
      signInOptions.push(GoogleAuthProvider.PROVIDER_ID);
    }
    this.uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: this.signInCallback
      },
      signInFlow: signInFlow,
      signInOptions: signInOptions
    };
  }
  useDeviceLanguage() {
    var userLang = navigator.language;
    i18n.changeLanguage(userLang);
  }

  handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const viewModel = this.state.viewModel;
    // $FlowFixMe
    viewModel.email = e.target.value;
    this.setState({ viewModel: viewModel });
  };

  handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const viewModel = this.state.viewModel;
    // $FlowFixMe
    viewModel.password = e.target.value;
    this.setState({ viewModel: viewModel });
  };

  handleForgotPassword = () => {
    this.props.callbackOpenForgotPassword();
    ReactGA.event({
      category: 'password',
      action: 'forgotPasswordButtonTapped',
    });
  };

  signInCallback = (authResult: any, redirectUrl: string) => {
    const { callbackSignInSucceeded } = this.props;
    this.setState({ loading: true });
    const currentUser = auth.currentUser;
    if (currentUser) {
      currentUser
        .getIdToken(true)
        .then(function (idToken) {
          service.Account(idToken, currentUser.uid, response => {
            if (response) {
              service.signIn(idToken, response => {
                callbackSignInSucceeded(response.accountId);
                ReactGA.event({
                  category: 'signIn',
                  action: 'signInSucceeded',
                });
              });
            }
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  componentWillUnmount() {
    //this.unregisterAuthObserver();
  }

  handleCredentialResponse(response: CredentialResponse, signup: SignUp) {
    // Build Firebase credential with the Google ID token.
    const idToken = response.credential;
    const credential = GoogleAuthProvider.credential(idToken);

    // Sign in with credential from the Google user.
    const result =
      signInWithCredential(auth, credential)
        .then(() => {
          signup.signInCallback(null, "");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The credential that was used.
          // const credential = firebase.auth.GoogleAuthProvider.credentialFromError(error);
          // ...
          console.log("ERROR with Google login");
        });
  };

  componentDidMount() {
    if (isDesktopApp()) {
      i18n.on('languageChanged', () => { this.renderGoogleButton() });

      let scriptId = "gsiScript";
      if (document.getElementById(scriptId)) {
        this.initializeGsi();
      }
      else {
        const script = document.createElement("script");
        script.setAttribute("id", scriptId)
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);

        document.getElementById(scriptId)!.addEventListener("load", () => {
          this.initializeGsi();
        });
      }
    }
  };

  initializeGsi() {
    (window as any).google.accounts.id.initialize({
      client_id: "952297686491-i8ej3ffcpk52gpi62s5un911jhkmnkc7.apps.googleusercontent.com",
      callback: (data: any) => this.handleCredentialResponse(data, this)
    });
    this.setState({ gsiInit: true });
    this.renderGoogleButton();
  };
  renderGoogleButton() {
    if (this.state.gsiInit) {
      (window as any).google.accounts.id.renderButton(
        document.getElementById("googleLogin")!,
        { theme: "outline", size: "large", locale: localStorage.getItem('lang') || navigator.language }  // customization attributes
      );
      (window as any).google.accounts.id.prompt(); // also display the One Tap dialog
    }
  };

  handleSignUp = () => {
    this.setState({ signUp: true, signIn: false });
  };
  handleSignIn = () => {
    this.setState({ signIn: true, signUp: false });
  };

  render() {
    const config = this.uiConfig as any;
    const { t } = this.props;

    let googleTdButton;
    let isDesktop = isDesktopApp();
    if (isDesktop) {
      googleTdButton = <div style={{ marginBottom: '14px' }}><div id="googleLogin"></div></div>;
    }
    else {
      googleTdButton = <></>;
    }

    const language = localStorage.getItem('lang');

    return (
      <SignUpPage>
        <SignInNavbar
          isWeb={!isDesktop}
          handleClickLogIn={this.props.onLogInClick}
          handleClickSignUp={() => { }}
        />

        <div
          className="d-flex"
          style={{ width: '100%', height: 'calc(100% - 50px)' }}>
          <SignUpForm>
            <div
              style={{ width: '100%', maxWidth: '400px', textAlign: 'center' }}>
              <WelcomeHeader>{t('Auth.signUp')}</WelcomeHeader>
            </div>

            <TranslateAuth type="SignUp" uiCallBack={(showGoogleButton) => {
                var googleButton = document.getElementById("googleLogin");
                if (googleButton) {
                  googleButton.style.display = showGoogleButton ? "block" : "none";
                }
              } 
            }>
              <StyledFirebaseAuth
                key={Math.random()}
                uiConfig={config}
                firebaseAuth={auth}
                className="firebase-auth"
              />
            </TranslateAuth>
            {googleTdButton}
            <RedirectToSignIn>
              {t('Auth.AlreadyAccount')}{' '}
              <LinkText onClick={this.props.onLogInClick}>
                {t('Auth.Login')}
              </LinkText>
            </RedirectToSignIn>
          </SignUpForm>

          <ImageTestimonial
            testimonials={Testimonials}
            images={[media1, media2]}
          />
        </div>
        <TermsAndConditions language={language ?? 'en'} t={t} />
      </SignUpPage>
    );
  }
}
export default withTranslation()(SignUp);

const SignUpPage = styled.div`
  width: 100%;
  height: calc(100vh - 10px);
`;

const WelcomeHeader = styled.h2`
  color: var(--basic-black, #0f0f0f);
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 120% */
  letter-spacing: -0.6px;
`;

const SignUpForm = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RedirectToSignIn = styled.div`
  color: var(--woodsmoke-900, #3d3d3d);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;

const LinkText = styled.span`
  color: var(--picton-blue-500, #01b6e9);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
  cursor: pointer;
`;